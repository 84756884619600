/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activate-account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-toasta/ngx-toasta.ngfactory";
import * as i3 from "ngx-toasta";
import * as i4 from "../../../../services/account.service";
import * as i5 from "@angular/common/http";
import * as i6 from "../../../../services/session.service";
import * as i7 from "./activate-account.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../../services/i18n.service";
var styles_ActivateAccountComponent = [i0.styles];
var RenderType_ActivateAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivateAccountComponent, data: {} });
export { RenderType_ActivateAccountComponent as RenderType_ActivateAccountComponent };
export function View_ActivateAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-toasta", [], null, null, null, i2.View_ToastaComponent_0, i2.RenderType_ToastaComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToastaComponent, [i3.ToastaConfig, i3.ToastaService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "row justify-content-center no-gutters vertical-align"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ActivateAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-activate-account", [], null, null, null, View_ActivateAccountComponent_0, RenderType_ActivateAccountComponent)), i1.ɵprd(512, null, i4.AccountService, i4.AccountService, [i5.HttpClient, i6.SessionService]), i1.ɵdid(2, 245760, null, 0, i7.ActivateAccountComponent, [i8.Router, i8.ActivatedRoute, i4.AccountService, i3.ToastaService, i3.ToastaConfig, i9.I18nService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ActivateAccountComponentNgFactory = i1.ɵccf("app-activate-account", i7.ActivateAccountComponent, View_ActivateAccountComponent_Host_0, {}, {}, []);
export { ActivateAccountComponentNgFactory as ActivateAccountComponentNgFactory };
