/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./brands.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-swiper-wrapper";
import * as i3 from "@angular/common";
import * as i4 from "./brands.component";
var styles_BrandsComponent = [i0.styles];
var RenderType_BrandsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BrandsComponent, data: {} });
export { RenderType_BrandsComponent as RenderType_BrandsComponent };
function View_BrandsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "swiper-lazy"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/images/brands/", _v.context.$implicit.file, ""); _ck(_v, 2, 0, currVal_0); }); }
function View_BrandsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "brands-carousel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "swiper-container"]], null, null, null, null, null)), i1.ɵdid(2, 5128192, null, 0, i2.SwiperDirective, [i1.PLATFORM_ID, i1.NgZone, i1.ElementRef, i1.KeyValueDiffers, [2, i2.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "swiper-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrandsComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.brands; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_BrandsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BrandsComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.brands.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BrandsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-brands", [], null, null, null, View_BrandsComponent_0, RenderType_BrandsComponent)), i1.ɵdid(1, 4308992, null, 0, i4.BrandsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BrandsComponentNgFactory = i1.ɵccf("app-brands", i4.BrandsComponent, View_BrandsComponent_Host_0, {}, {}, []);
export { BrandsComponentNgFactory as BrandsComponentNgFactory };
