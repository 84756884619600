import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private readonly filters = {};

  get(_id: string): Object {
    return this.filters[_id];
  }

  set(_id: string, filters: Object): void {
    this.filters[_id] = filters;
  }
}
