import * as i0 from "@angular/core";
export class RucService {
    constructor() { }
    //Handler para el evento cuando cambia el input
    //Elimina cualquier caracter espacio o signos habituales y comprueba validez
    validaRuc(input) {
        var dni = input.replace(/[-.,[\]()\s]+/g, "");
        //Es entero?       
        if (this.rucValido(dni) && (dni = Number(dni)) && dni % 1 === 0) { // ⬅️ Acá se comprueba
            return true;
        }
        else {
            return false;
        }
    }
    // Devuelve un booleano si es un DNI válido
    // (deben ser 8 dígitos sin otro caracter en el medio)
    rucValido(dni) {
        var regexDni = /^\d{8}(?:[-\s]\d{4})?$/;
        if (regexDni.test(dni) == true) {
            return true;
        }
        else {
            return false;
        }
    }
}
RucService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RucService_Factory() { return new RucService(); }, token: RucService, providedIn: "root" });
