import { Component, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  goToPage(page : string){
    this.router.navigate([ page ]);
  }

}
