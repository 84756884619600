import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from '@services/auth.guard';
import { MasterPagePrivateComponent } from '@pages/master/master-page-private/master-page-private.component';
import { MasterPagePublicComponent } from '@pages/master/master-page-public/master-page-public.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { LoginComponent } from '@pages/public/login/login.component';
import { LoginSSOComponent } from '@pages/public/login-sso/login-sso-component';
import { RecoverPasswordComponent } from '@pages/public/recover-password/recover-password.component';
import { RegisterComponent } from '@pages/public/register/register.component';
import { ActivatePasswordComponent } from '@pages/public/activate/activate-password/activate-password.component';
import { ActivateAccountComponent } from '@pages/public/activate//activate-account/activate-account.component';
import { RepresentationComponent } from '@pages/private/representation/representation.component';
import { TeamStatementsComponent } from '@pages/private/team-statements/team-statements.component';
import { SearchStatementsComponent } from '@pages/private/search-statements/search-statements.component';
import { HomeComponent } from '@pages/private/home/home.component';
import { FaqComponent } from '@pages/private/faq/faq.component';
import { ExpiredComponent } from './pages/public/expired/expired.component';

const routes: Routes = [
  {
    path: '', component: MasterPagePrivateComponent, children: [
      { path: '',                    component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'statement',           loadChildren: '@pages/private/statement/statement.module#StatementModule', canActivate: [AuthGuard] },
      { path: 'team',                component: TeamStatementsComponent, canActivate: [AuthGuard] },
      { path: 'search',              component: SearchStatementsComponent, canActivate: [AuthGuard] },
      { path: 'reportability',       loadChildren: '@pages/private/reportability/reportability.module#ReportabilityModule', canActivate: [AuthGuard] },
      { path: 'user-manager',        loadChildren: '@pages/private/user-maintainer/user-maintainer.module#UserMaintainerModule', canActivate: [AuthGuard] },
      { path: 'faq',                 component: FaqComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: '', component: MasterPagePublicComponent, children: [
      { path: 'login',                   component: LoginComponent },
      { path: 'login-success',           component: LoginSSOComponent },
      { path: 'register',                component: RegisterComponent },
      { path: 'activate/password/:hash', component: ActivatePasswordComponent},
      { path: 'activate/account/:hash',  component: ActivateAccountComponent},
      { path: 'representation',          component: RepresentationComponent, canActivate: [AuthGuard] },
      { path: 'password',                component: RecoverPasswordComponent },
      { path: 'expired',                 component: ExpiredComponent },
    ]
  },
  { path: '**',                  component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
