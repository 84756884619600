import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-panel-information',
  templateUrl: './panel-information.component.html',
  styleUrls: ['./panel-information.component.scss']
})
export class PanelInformationComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  loading : Boolean;
  constructor(private utilService : UtilService) { }

  ngOnInit() {
    this.subscription.add(this.utilService.progressBar$.subscribe(
      load => { 
        this.loading = load
      }
    ));
  }

}


