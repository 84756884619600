
<div class="flex-container flex-column" *ngIf="representatives != null">
  <div class="col-lg-4 col-md-6 login-fancy-bg bg">
    <app-panel-information></app-panel-information>
  </div>
  <div class="col-lg-4 col-md-6 p-0 bg-white">
    <div class="login-fancy pb-20 clearfix">
      <div class="user-info mb-20 p-0 col-12 ">
        <h5 class="name mb-10 text-center">Bienvenido {{profile.firstName}} {{profile.lastName}}</h5>
        <p class="text-center"><i class="fa fa-lock"></i> Seleccione el usuario con el cuál que desea consultar y/o declarar </p>
      </div>
      <div class="flow-wrap controls col-12 p-0 mb-20 mt-10">
        <div class="col-12 p-0">
          <label class="radio w-100"> 
            <input type="radio" name="confirmAccount" value="{{profile.rut}}" (click)="confirmUser(profile.rut)" checked class="isRepresentation"/> {{profile.firstName}} {{profile.lastName}} {{ profile.secondLastName }}
          </label>
          <label class="radio w-100" *ngFor="let representative of representatives">
              <input type="radio" name="confirmAccount" value="{{representative.rut}}" class="rd"  (click)="confirmUser(representative.rut)" /> {{ representative.firstName }}  {{ representative.lastName }} {{ representative.secondLastName }} 
          </label>
        </div>
      </div>
      <button (click)="goToPage()" [disabled]="selected == ''" class="button w-100 mt-20">Confirmar</button>
    </div>
  </div>
</div> 