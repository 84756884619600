import * as i0 from "@angular/core";
export class FiltersService {
    constructor() {
        this.filters = {};
    }
    get(_id) {
        return this.filters[_id];
    }
    set(_id, filters) {
        this.filters[_id] = filters;
    }
}
FiltersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FiltersService_Factory() { return new FiltersService(); }, token: FiltersService, providedIn: "root" });
