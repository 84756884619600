<ngx-toasta></ngx-toasta>
<div class="flex-container flex-column">
  <div class="col-lg-6 col-md-8 login-fancy-bg bg">
    <app-panel-information></app-panel-information>
  </div>
  <div class="col-lg-6 col-md-8 row login-container">
    <div class="col-lg-12 col-md-12 p-0 bg-white" [ngClass]="{ 'login-basic' : basicLogin }">
      <div class="login-saml2">
        <h4 class="mb-10 text-center text-secondary">Plataforma de Registros de Compliance</h4>
        <h3 class="mb-10 text-center">Acceso de usuario</h3>
        <div class="flex-column mt-10 w-100">
          <a [href]="connection.link"
             class="btn btn-outline-dark d-flex flex-column justify-content-center align-items-center mb-20" style="height: 60px;"
             *ngFor="let connection of ldapConnections">
                <span>¿Eres colaborador de {{ connection.description }}?</span>
                <span>Haz click aquí</span>
          </a>
          <a [href]=""
             (click)="goToPage('/login', { queryParams : { basic : true } })"
             class="btn btn-primary d-flex flex-column justify-content-center align-items-center mb-20" style="height: 60px;">
                <span>¿Eres colaborador del Grupo CINTAC?</span>
                <span>Haz click aquí</span>
          </a>
          <a [href]=""
             (click)="goToPage('/login', { queryParams : { basic : true } })"
             class="btn btn-primary d-flex flex-column justify-content-center align-items-center mb-20" style="height: 60px;">
                <span>¿Eres colaborador de otra sociedad del grupo CAP?</span>
                <span>Haz click aquí</span>
          </a>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 p-0 bg-white" [ngClass]="{ 'login-basic' : basicLogin }">
      <div class="login-fancy pb-20 clearfix">
        <!-- <h3 class="mb-10 text-center">Plataforma de Registros de Compliance <br> Acceso de Contraseña</h3> -->
        <h4 class="mb-10 text-center text-secondary">Plataforma de Registros de Compliance</h4>
        <h3 class="mb-10 text-center">Acceso con contraseña</h3>
        <div class="d-flex flex-column align-items-center mb-20">
          <p class="mb-10 text-center">Ingrese su número y tipo de documento y su contraseña</p>
        </div>
        <form [formGroup]="loginForm" class="p-20" (submit)="doLogin()">

          <div class="d-flex justify-content-between flex-nowrap p-0">
            <div class="form-group w-100 p-0">
              <select class="custom-select mr-sm-2" formControlName="document" (change)="cleanInputs()">
                <option value="0" selected disabled>(*) Tipo de documento</option>
                <option value="1">RUT</option>
                <option value="2">RUC</option>
                <option value="3">DNI</option>
              </select>
            </div>
            <div class="form-group p-0" >
              <app-field-validation [control]="loginForm.controls.document" ></app-field-validation>
            </div>
          </div>

          <div class="d-flex justify-content-between flex-nowrap p-0">
            <div class="form-group w-100 p-0" >
              <input class="web form-control" type="text" autocomplete="new-password" minlength="9" maxlength="12"
                formControlName="rut" placeholder="(*) Número de documento" (blur)="onBlurRut()" (focus)="onFocusRut()">
            </div>
            <div class="form-group p-0" >
              <app-field-validation [control]="loginForm.controls.rut" ></app-field-validation>
            </div>
          </div>
          <div class="d-flex justify-content-between flex-nowrap p-0">
            <div class="form-group w-100 p-0" >
              <input class="password form-control" type="password" autocomplete="new-password" minlength="8" maxlength="20"
                formControlName="password" placeholder="(*) Contraseña">
            </div>
            <div class="form-group p-0" >
              <app-field-validation [control]="loginForm.controls.password" [tooltip]="tips.password"></app-field-validation>
            </div>
          </div>
          <button type="submit" [disabled]="loginForm.invalid" class="button w-100 mb-10 mt-10">Acceder</button>
          <div class="flow-wrap col-12 p-0">
            <div class="form-group col-12 p-0 text-center" >
              No tiene contraseña o no la recuerda<br>
              <a [href]="" (click)="goToPage('/password')" class="link-compliance">
                  Solicitar clave
                <mat-icon class="mat-icon-md">keyboard_arrow_right</mat-icon>
              </a>
            </div>
          </div>
        </form>
        <div class="section-field">
          <div class="col-xs-6">
            <a (click)="goToPage('/login')" class="font-medium deco-under cursor-pointer float-left">Acceso de red</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

