
<ngx-toasta></ngx-toasta>
<div class="flex-container flex-column">
  <div class="col-lg-4 col-md-6 login-fancy-bg bg">
    <app-panel-information></app-panel-information>
  </div>
  <div class="col-lg-4 col-md-6 p-0 bg-white">
    <div class="login-fancy pb-20 clearfix">
      <h3 class="mb-10 text-center">Registro de Usuario</h3>
      <p class="mb-20 text-center">Para registrar su cuenta de usuario debe completar los siguientes datos marcados con (*).</p>
      <form [formGroup]="registerForm">
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <select class="custom-select" formControlName="company" (change)="changeCompany()">
              <option value="0">(*) Empresa</option>
              <option *ngFor="let company of companies | async" [value]="company.id">
                {{company.name}}
              </option>
            </select>
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.company" [tooltip]="tips.company"></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input type="supervisor" placeholder="(*) Email Supervisor" formControlName="emailSupervisor" class="form-control" maxlength="40" autocomplete="off">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.emailSupervisor" [tooltip]="tips.supervisor"></app-field-validation>
          </div>
        </div>

        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0">            
            <select class="custom-select mr-sm-2" formControlName="document" (change)="cleanInputs()">
              <option value="0" selected disabled>(*) Tipo de documento</option>
              <option value="1">RUT</option>
              <option value="2">RUC</option>
              <option value="3">DNI</option>
            </select>
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.document" ></app-field-validation>
          </div>
        </div>


        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input type="text" placeholder="(*) Número de documento" formControlName="rut" autocomplete="off" class="form-control" 
              (blur)="onBlurRut()" (focus)="onFocusRut()" minlength="8" maxlength="12">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.rut" [tooltip]=""></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input type="email" placeholder="Email" formControlName="newEmail" autocomplete="off" class="form-control"
              minlength="10" maxlength="40">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.newEmail" [tooltip]="tips.email"></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="web form-control" type="text" formControlName="name" autocomplete="off" placeholder="(*) Nombre" minlength="3" maxlength="35">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.name" [tooltip]="tips.name"></app-field-validation>
          </div>
        </div>

        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="web form-control" type="text" formControlName="lastName" autocomplete="off" placeholder="(*) Apellido Paterno" minlength="3" maxlength="35">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.lastName" [tooltip]="tips.lastName"></app-field-validation>
          </div>
        </div>

        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="web form-control" type="text" formControlName="lastSecondName" autocomplete="aa" placeholder="(*) Apellido Materno" minlength="3" maxlength="35">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.lastSecondName" [tooltip]="tips.secondName"></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="password form-control" type="password" formControlName="newPassword" autocomplete="new-password" placeholder="(*) Contraseña" 
            minlength="8" maxlength="20">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.newPassword" [tooltip]="tips.password"></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="password form-control" type="password" formControlName="confirmNewPassword" autocomplete="new-password" placeholder="(*) Confirmar Contraseña" 
            minlength="8" maxlength="20">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.confirmNewPassword" [tooltip]="tips.confirmPassword"></app-field-validation>
          </div>
        </div>
        <button (click)="doRegister()" [disabled]="registerForm.invalid" class="button w-100 mb-10 mt-10">Registrar</button>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-12 p-0 text-center" >
            ¿Necesitas ayuda? Ingresa a<br> 
            <a href="https://compliancecap.koomedia.cl/" target="_blank" class="link-compliance">
                CAP Integridad
              <mat-icon class="mat-icon-md">keyboard_arrow_right</mat-icon>
            </a>
          </div>
        </div>
      </form>
      <div class="section-field">
        <div class="col-xs-6">
          <a (click)="goToPage('/login', { queryParams : { basic : true } })" class="font-medium deco-under cursor-pointer float-left">Acceder</a>
        </div>
        <div class="col-xs-6">
          <a (click)="goToPage('/password')" class="font-medium deco-under cursor-pointer float-right">Solicitar clave</a>
        </div>
      </div>
    </div>
  </div>
</div>
