import { Component, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  screenType: string;
  downloadLink: string;
  constructor(private  utilSrv: UtilService) { 
    this.screenType = this.utilSrv.getScreenSize();

  }

  ngOnInit() {
    this.downloadLink = "https://clcapcompliancestorage.blob.core.windows.net/documents/terminologia.docx";
  }

}
