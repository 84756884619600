import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { ConstantService } from '@services/constant.service';
@Injectable()
export class UserManagerService {
    head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
    userPerPage:number = 10;
    constructor(public http: HttpClient) { }
   
    findUsers(filters : any) : Observable<any> {

      let skip  = 0;
      let where = '';

      if (filters.page > 1){
        skip = this.userPerPage * (filters.page - 1);
      }

      if (!!filters.lastName || !!filters.sociedad){
        let lastNameCond = (!!filters.lastName) ? '"lastName": "' + filters.lastName + '"' : '';
        let sociedadCond = (!!filters.sociedad) ? '"internalCompany": ' + filters.sociedad : '';
        let separator    = (!!lastNameCond && !!sociedadCond) ? ',' : '';
        where = `"where" : { ${lastNameCond}${separator} ${sociedadCond} }, `;
      };

      var filter = '{ ' + where + '"skip": ' + skip + ', "limit": ' + this.userPerPage + ' }';

      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_UserList + "?filter=" + encodeURIComponent(filter))
      .pipe(map(response => {return response; }));

    }

    findById(rut : string){
      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_Root + rut)
      .pipe(map(response => {return response; }));
    }

    getUserByRut(rut : string){
      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_SearchByRut + rut)
      .pipe(map(response => {return response; }));
    }

    getUsersByRUt(representatives : any) : any {
        var requests = [];
        for (let i = 0; i < representatives.length; i++){
          requests.push(this.http.get<any>(environment.serverUrl + EndpointService.UserManager_SearchByRut + representatives[i].representTo));
        }
        return requests;
    }
    getUsersListByEmail(email : string) : Observable<any>  {
      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_UserList + "/" + email)
      .pipe(map(response => {return response; }));
    }

    getUsersCount(filters : any) : Observable<any>  {

      let where = '';

      if (!!filters.lastName || !!filters.sociedad){
        let lastNameCond = (!!filters.lastName) ? '"lastName": "' + filters.lastName + '"' : '';
        let sociedadCond = (!!filters.sociedad) ? '"internalCompany": ' + filters.sociedad : '';
        let separator    = (!!lastNameCond && !!sociedadCond) ? ',' : '';
        where = `{ ${lastNameCond}${separator} ${sociedadCond} }`;
      };

      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_UserCount + "?where=" + encodeURIComponent(where))
        .pipe(map(response => {return response; }));
    }

    getRoles(){
      var filter = '{ "limit": "10" }';
      return this.http.get<any>(environment.serverUrl + EndpointService.Role_List + "?filter=" + encodeURIComponent(filter))
        .pipe(map(response => {return response; }));
    }

    getUserStatusList(){
      return this.http.get<any>(environment.serverUrl + EndpointService.UserManager_StatusList)
        .pipe(map(response => {return response; }));
    }

    update(user : any){
      user.secondName = "";
      return this.http.put<any>(environment.serverUrl + EndpointService.UserManager_Root + user.rut, user)
      .pipe(map(response => {return response; }));
    }

    addUsersWithExcel(base64: string): Observable<any>{
      return this.http.post<any>(environment.serverUrl + EndpointService.UserManager_Excel, { base64 }).pipe(
        map( response => { return response; } )
      );
    }    

    changePassword(rut : string, password: string) : Observable<any> {
      let body = { rut: rut, password : password };
      return this.http.put<any>(environment.serverUrl + EndpointService.UserManager_ChangePassword, body, { headers: this.head })
        .pipe(map( response => { 
          return response 
        })
      );
    }

    getRepresentTo(rut : String) : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Representatives_RepresentTo + rut).pipe(
          map(response => { 
            return response; 
          })    
    )};

    addRepresentTo(represent : any) : Observable<any> {
      return this.http.post<any>(environment.serverUrl + EndpointService.Representatives_Root, represent, { headers: this.head }).pipe(
          map(response => { 
            return response; 
          })    
    )};

    getSepecificRepresentantion(rut : string, rutRepresenTo : string) : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Representatives_Specific + rut + "/" + rutRepresenTo).pipe(
          map(response => { 
            return response; 
        }
      ));
    }

    removeRepresentTo(rut : string, rutRepresenTo : string) : Observable<any> {
      return this.http.delete<any>(environment.serverUrl + EndpointService.Representatives_Remove + rut + "/" + rutRepresenTo).pipe(
          map(response => { 
            return response; 
        }
      ));
    } 

    findManagerByRut(rut : string) : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Representatives_Manager + rut).pipe(
          map(response => { 
            return response; 
        })    
    );
  }
}