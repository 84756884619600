import { Injectable } from '@angular/core';

@Injectable()
export class ConstantService {
    
    public static ContentTypeJson                   = "application/json";
    public static ContentTypeXml                    = "application/xml;";
    public static ContentTypePdf                    = "application/pdf;";
    public static ContentTypeUrlEncoded             = "application/x-www-form-urlencoded;";
    public static ContentTypeMultipart              = "multipart/form-data";
    public static ContentTypeTextPlain              = "text/plain;";
    public static ContentTypeImageJpg               = "image/jpeg;";
    public static ContentTypeImagePng               = "image/png;";
    public static ContentTypeImageGif               = "image/gif;";
    public static ContentTypeCharset                = "chartset="
    public static HttpMethodGET                     = "GET";
    public static HttpMethodPOST                    = "POST";
    public static HttpMethodPUT                     = "PUT";
    public static HttpMethodDELETE                  = "DELETE";
    public static EncodingUTF8                      = "UTF-8"; 
    public static EncodingISO88959                  = "ISO-8859-1";
    public static ProtocolHttp                      = "http://"; 
    public static ProtocolHttps                     = "https://";
    public static LangCodeES                        = "es";
    public static ToasterDismiss                    = 8000
    public static ToasterTheme                      = "bootstrap"
    public static SmartSearchDelaySrv               = 2500
    public static SmartSearchRetry                  = 10000
    public static UnknownError                      = "Unknown Error";
    public static Anonymous                         = "Anonymous"
  }