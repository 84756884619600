import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RucService {

  constructor() { }

    //Handler para el evento cuando cambia el input
    //Elimina cualquier caracter espacio o signos habituales y comprueba validez
    validaRuc(input) {
      var dni = input.replace(/[-.,[\]()\s]+/g, "");

      //Es entero?       
      if (this.rucValido(dni) && (dni = Number(dni)) && dni % 1 === 0) { // ⬅️ Acá se comprueba
          return true;
      } else {
          return false;
      }

  }

  // Devuelve un booleano si es un DNI válido
  // (deben ser 8 dígitos sin otro caracter en el medio)
  private rucValido(dni){
    var regexDni = /^\d{8}(?:[-\s]\d{4})?$/;
    if( regexDni.test (dni) == true){
      return true;   
    }else{
      return false;
     }
  }


  /**
   * REAL VALIDACION DE RUC - SE COMENTA PARA TEMPORALMENTE SOLO VALIDAR 8 DIGITOS
   */

  //   //Handler para el evento cuando cambia el input
  //   //Elimina cualquier caracter espacio o signos habituales y comprueba validez
  //   validaRuc(input) {
  //     var ruc = input.replace(/[-.,[\]()\s]+/g, "");

  //     //Es entero?    
  //     if ((ruc = Number(ruc)) && ruc % 1 === 0
  //         && this.rucValido(ruc)) { // ⬅️ Acá se comprueba
  //         return true;
  //     } else {
  //         return false;
  //     }

  // }

  // // Devuelve un booleano si es un RUC válido
  // // (deben ser 11 dígitos sin otro caracter en el medio)
  // private rucValido(ruc) {
  //     //11 dígitos y empieza en 10,15,16,17 o 20
  //     if (!(ruc >= 1e10 && ruc < 11e9
  //         || ruc >= 15e9 && ruc < 18e9
  //         || ruc >= 2e10 && ruc < 21e9))
  //         return false;

  //     for (var suma = -(ruc % 10 < 2), i = 0; i < 11; i++ , ruc = ruc / 10 | 0)
  //         suma += (ruc % 10) * (i % 7 + (i / 7 | 0) + 1);
  //     return suma % 11 === 0;

  // }
}
