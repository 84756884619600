import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { DxVectorMapModule, DxSelectBoxModule, DxTextBoxModule } from 'devextreme-angular';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { AppInterceptor } from './app-interceptor';
import { AuthGuard} from '@services/auth.guard';
import { MatDialogModule } from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material';


import { LoginComponent } from '@pages/public/login/login.component';
import { LoginSSOComponent } from '@pages/public/login-sso/login-sso-component';
import { RecoverPasswordComponent } from '@pages/public/recover-password/recover-password.component';
import { RegisterComponent } from '@pages/public/register/register.component';
import { ActivatePasswordComponent } from '@pages/public/activate/activate-password/activate-password.component';
import { ActivateAccountComponent } from '@pages/public/activate//activate-account/activate-account.component';
import { RepresentationComponent } from '@pages/private/representation/representation.component';
import { NotFoundComponent } from '@pages/not-found/not-found.component';
import { MasterPagePublicComponent } from '@pages/master/master-page-public/master-page-public.component';
import { MasterPagePrivateComponent } from '@pages/master/master-page-private/master-page-private.component';
import { TeamStatementsComponent } from '@pages/private/team-statements/team-statements.component';
import { HomeComponent } from '@pages/private/home/home.component';
import { FaqComponent } from '@pages/private/faq/faq.component';
import { ExpiredComponent } from './pages/public/expired/expired.component';
import { SearchStatementsComponent } from './pages/private/search-statements/search-statements.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MasterPagePublicComponent,
    MasterPagePrivateComponent,
    LoginComponent,
    LoginSSOComponent,
    RecoverPasswordComponent,
    RegisterComponent,
    ActivatePasswordComponent,
    ActivateAccountComponent,
    RepresentationComponent,
    TeamStatementsComponent,
    HomeComponent,
    FaqComponent,
    ExpiredComponent,
    SearchStatementsComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DxVectorMapModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    NgbModule,
    SharedModule,
    FormsModule, 
    ReactiveFormsModule,
    MatDialogModule,
    MatRadioModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
