/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-sso.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../shared/panel-information/panel-information.component.ngfactory";
import * as i8 from "../../../shared/panel-information/panel-information.component";
import * as i9 from "../../../services/util.service";
import * as i10 from "./login-sso-component";
var styles_LoginSSOComponent = [i0.styles];
var RenderType_LoginSSOComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginSSOComponent, data: {} });
export { RenderType_LoginSSOComponent as RenderType_LoginSSOComponent };
function View_LoginSSOComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-12 p-20 bg-white d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["diameter", "50"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Redirigiendo..."]))], function (_ck, _v) { var currVal_7 = "50"; var currVal_8 = "indeterminate"; _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; var currVal_3 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 2).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 2).mode === "determinate") ? i1.ɵnov(_v, 2).value : null); var currVal_6 = i1.ɵnov(_v, 2).mode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_LoginSSOComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-12 p-20 bg-white d-flex flex-column justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "mt-20"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Falta Token"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "btn btn-danger mt-20"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Volver a Inicio de Sesi\u00F3n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; _ck(_v, 3, 0, currVal_0); }); }
export function View_LoginSSOComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "flex-container flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-4 col-md-6 login-fancy-bg bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-panel-information", [], null, null, null, i7.View_PanelInformationComponent_0, i7.RenderType_PanelInformationComponent)), i1.ɵdid(3, 114688, null, 0, i8.PanelInformationComponent, [i9.UtilService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-lg-4 col-md-6 row p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginSSOComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginSSOComponent_2)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = !_co.showError; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.showError; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_LoginSSOComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-sso", [], null, null, null, View_LoginSSOComponent_0, RenderType_LoginSSOComponent)), i1.ɵdid(1, 114688, null, 0, i10.LoginSSOComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginSSOComponentNgFactory = i1.ɵccf("app-login-sso", i10.LoginSSOComponent, View_LoginSSOComponent_Host_0, {}, {}, []);
export { LoginSSOComponentNgFactory as LoginSSOComponentNgFactory };
