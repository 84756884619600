import { Component, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-master-page-public',
  templateUrl: './master-page-public.component.html',
  styleUrls: ['./master-page-public.component.scss']
})
export class MasterPagePublicComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  progressBar$: any;
  loading : Boolean;
  constructor(private utilService : UtilService) { 
  }

  ngOnInit() {
    this.subscription.add(this.utilService.progressBar$.subscribe(
      load => { 
        this.loading = load
      }
    ));
  }

}
