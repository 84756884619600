<ngx-toasta></ngx-toasta>
<div class="flex-container flex-column" *ngIf="showPage">
    <div class="col-lg-4 col-md-6 login-fancy-bg bg">
        <app-panel-information></app-panel-information>
    </div>
    <div class="col-lg-4 col-md-6 p-0 bg-white">
        <div class="login-fancy pb-20 clearfix">
            <h3 class="mb-10 text-center">Cambiar Clave</h3>
            <p class="mb-20 text-center">Para cambiar su contraseña ingrese una nueva clave</p>
            <form [formGroup]="registerForm">
            <div class="flow-wrap col-xl-12 p-0">
                <div class="form-group col-xl-10 p-0" >
                <input class="password form-control" type="password" autocomplete="new-password" formControlName="newPassword" placeholder="(*) Contraseña" tabindex="1"
                minlength="8" maxlength="20">
                </div>
                <div class="form-group col-xl-2 p-0" >
                <app-field-validation [control]="registerForm.controls.newPassword" [tooltip]="tips.password"></app-field-validation>
                </div>
            </div>
            <div class="flow-wrap col-xl-12 p-0">
                <div class="form-group col-xl-10 p-0" >
                <input class="password form-control" type="password" autocomplete="new-password" formControlName="confirmNewPassword" placeholder="(*) Confirmar Contraseña" tabindex="2"
                minlength="8" maxlength="20">
                </div>
                <div class="form-group col-xl-2 p-0" >
                <app-field-validation [control]="registerForm.controls.confirmNewPassword" [tooltip]="tips.confirmPassword"></app-field-validation>
                </div>
            </div>
            <button (click)="updatePassword()" [disabled]="registerForm.invalid" class="button w-100 mb-20 mt-10" tabindex="3">Cambiar Contraseña</button>
            </form>
        </div>
    </div>
</div>
