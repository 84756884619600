import { Injectable } from '@angular/core';

@Injectable()
export class EndpointService {
  public static Api                                             = "";
  public static ActionPlans                                     = EndpointService.Api + "action-plans";
  public static Authentication_Authenticate                     = EndpointService.Api + "users/login";
  public static Authentication_IsLoggedIn                       = EndpointService.Api + "logged-in"
  public static Account_Activate                                = EndpointService.Api + "users/activate/"
  public static Account_Register                                = EndpointService.Api + "users"
  public static Account_Profile                                 = EndpointService.Api + "users/me"
  public static Account_Supervisors                             = EndpointService.Api + "users/supervisors/"
  public static ClassificationPerson                            = EndpointService.Api + "classification-people"
  public static Companies                                       = EndpointService.Api + "companies"
  public static Declarations                                    = EndpointService.Api + "declarations"
  public static Declarations_Open                               = EndpointService.Api + "declarations/open/"
  public static Declarations_Close                              = EndpointService.Api + "declarations/close/"
  public static Declarations_SearchByRut                        = EndpointService.Api + "declarations/searchByRut/"
  public static Declarations_Supervisor                         = EndpointService.Api + "declarations/supervisor/"
  public static Declarations_All                                = EndpointService.Api + "declarations/all"
  public static Declarations_SearchByType                       = EndpointService.Api + "declarations/searchByType/"
  public static Declaration_Conflict                            = EndpointService.Api + "declaration-conflicts"
  public static Declaration_ConflictByYear                      = EndpointService.Api + "declarations/conflict/year/"
  public static Declaration_Gift                                = EndpointService.Api + "declaration-gifts"
  public static Declaration_Hospitality                         = EndpointService.Api + "declaration-hospitalities"
  public static Declaration_Meeting                             = EndpointService.Api + "declaration-meetings"
  public static Documents                                       = EndpointService.Api + "documents"
  public static StorageDocuments                                = EndpointService.Api + "document/declaration/"
  public static EntityMeeting                                   = EndpointService.Api + "entity-meetings"
  public static EntityRelationship                              = EndpointService.Api + "entity-relationships"
  public static TypeRelationship                                = EndpointService.Api + "type-relationships"
  public static InteractionType                                 = EndpointService.Api + "interaction-types"
  public static InternalCompany_List                            = EndpointService.Api + "internal-companies"
  public static MeetingPersons                                  = EndpointService.Api + "meeting-persons"
  public static ConflictPersons                                 = EndpointService.Api + "conflict-persons"
  public static Representatives_Root                            = EndpointService.Api + "representatives"
  public static Representatives_List                            = EndpointService.Api + "representatives/search/"
  public static Representatives_Manager                         = EndpointService.Api + "representatives/manager/"
  public static Representatives_RepresentTo                     = EndpointService.Api + "representatives/represent/"
  public static Representatives_Specific                        = EndpointService.Api + "representatives/get/"
  public static Representatives_Remove                          = EndpointService.Api + "representatives/remove/"
  public static Representatives_isRepresented                   = EndpointService.Api + "representatives/represented/"
  public static RecoverPassword_Request                         = EndpointService.Api + "password/recover-passwords"
  public static Recover_SetPassword                             = EndpointService.Api + "password/set-password"
  public static Recover_UpdatePassword                          = EndpointService.Api + "password/update-password"
  public static Recover_CheckHash                               = EndpointService.Api + "password/checkHash/"
  public static Role_List                                       = EndpointService.Api + "roles"
  public static People                                          = EndpointService.Api + "people"
  public static UserManager_Root                                = EndpointService.Api + "users-manager/"
  public static UserManager_Find                                = EndpointService.Api + "users-manager?filter="
  public static UserManager_SearchByRut                         = EndpointService.Api + "users-manager/search/"
  public static UserManager_StatusList                          = EndpointService.Api + "users-manager/status/list"
  public static UserManager_UserList                            = EndpointService.Api + "users-manager/list"
  public static UserManager_UserCount                           = EndpointService.Api + "users-manager/count"
  public static UserManager_ChangePassword                      = EndpointService.Api + "users-manager/change-password"
  public static UserManager_Excel                               = EndpointService.Api + "users-manager/excel"
  public static TypeHospitalityGift                             = EndpointService.Api + "type-hospitality-gifts"

  public static Reportability_ConflictStatus                      = EndpointService.Api + "reportability/conflictStatus"
  public static Reportability_ForCompany                          = EndpointService.Api + "reportability/forCompany"
  public static Reportability_ConflictType                        = EndpointService.Api + "reportability/conflictType"
  public static Reportability_MeetingType                         = EndpointService.Api + "reportability/meetingType"
  public static Reportability_ConflictExcel                       = EndpointService.Api + "reportability/conflictExcel"
  public static Reportability_MeetingExcel                        = EndpointService.Api + "reportability/meetingExcel"
  public static Reportability_MeetingForCompany                   = EndpointService.Api + "reportability/meetingForCompany"
  public static Reportability_GiftHospitality                     = EndpointService.Api + "reportability/gift-hospitality"

  public static Reportability_DeclarationConflict                 = EndpointService.Api + "reportability/declarationConflict"
  public static Reportability_DeclarationMeeting                  = EndpointService.Api + "reportability/declarationMeeting"
  public static Reportability_DeclarationGift                     = EndpointService.Api + "reportability/declarationGift"
  public static Reportability_General                             = EndpointService.Api + "reportability/general"
  
}
