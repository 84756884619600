import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { ConstantService } from '@services/constant.service';
import { SessionService } from '@services/session.service';

interface personType{
  id          : string,
  description : string
};

interface personRole{
  id          : string,
  description : string
};

@Injectable()
export class DeclarationService {
    declarationPerPage = 10;
    head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
    headMp = new HttpHeaders({ 'Content': ConstantService.ContentTypeMultipart });
    private personTypes : Array<personType> = [];
    private personRoles : Array<personRole> = [];
    constructor(public http: HttpClient, private sessionService : SessionService) {     

      this.personTypes.push({ id : '1', description : 'Funcionario Público' });
      this.personTypes.push({ id : '2', description : 'Persona Influyente' });
      this.personTypes.push({ id : '3', description : 'Competidor' });

      this.personRoles.push({ id : '1', description : 'Presidente de Asociación Gremial' });
      this.personRoles.push({ id : '2', description : 'Líder/Dirigente Sindical' });
      this.personRoles.push({ id : '3', description : 'Líder/Representante Comunidad' });
      this.personRoles.push({ id : '4', description : 'Líder/Representante Comunidad Indígena' });
      this.personRoles.push({ id : '5', description : 'Presidente Junta de Vecinos' });
      this.personRoles.push({ id : '6', description : 'Otro' });

    }

    creationDeclaration(declaration : any){
      return this.http.post<any>(environment.serverUrl + EndpointService.Declarations, declaration, { headers: this.head }).pipe(
        map(response => {
          return response;
        })
      );
    }

    getDeclaration(declarationId : number): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Declarations + "/" + declarationId).pipe(
        map(response => { return response; })
      );
    }

    openDeclaration(declarationId : number): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Declarations_Open + declarationId).pipe(
        map(response => { return response; })
      );
    }

    closeDeclaration(declarationId : number): Observable<any> {
      return this.http.delete<any>(environment.serverUrl + EndpointService.Declarations_Close + declarationId).pipe(
        map(response => { return response; })
      );
    }

    getDeclarationConflict(declarationId : number): Observable<any> {
      var filter = '{ "where" : { "declarationId": "' + declarationId + '" }}';
      return this.http.get<any>(environment.serverUrl + EndpointService.Declaration_Conflict + "?filter=" + encodeURIComponent(filter)).pipe(
        map(response => {
          return response;
        })
      );
  }

  getDeclarationGift(declarationId : number): Observable<any> {
    var filter = '{ "where" : { "declarationId": "' + declarationId + '" }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.Declaration_Gift + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getDeclarationHospitality(declarationId : number): Observable<any> {
    var filter = '{ "where" : { "declarationId": "' + declarationId + '" }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.Declaration_Hospitality + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getDeclarationMeeting(declarationId : number): Observable<any> {
    var filter = '{ "where" : { "declarationId": "' + declarationId + '" }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.Declaration_Meeting + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getMeetingPersons(declarationId : number){
    var filter = '{ "where" : { "declarationMeetingId": "' + declarationId + '" }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.MeetingPersons + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getConflictPersons(declarationId : number){
    var filter = '{ "where" : { "declarationConflictId": "' + declarationId + '" }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.ConflictPersons + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getDeclarationConflictByYear(rut : string): Observable<any> {
    return this.http.get<any>(environment.serverUrl + EndpointService.Declaration_ConflictByYear + rut).pipe(
      map(response => {
        this.sessionService.setDeclarationYear(response);
        return response; 
      })
    );
  }

  getDeclarationByType(rut : string, type : string): Observable<any> {
    return this.http.get<any>(environment.serverUrl + EndpointService.Declarations_SearchByType + rut + "/" +type).pipe(
      map(response => { return response; })
    );
  }

  getPersonTypes(): Array<personType> { return this.personTypes }
  getPersonRoles(): Array<personRole> { return this.personRoles }
  
  createDeclarationConflict(declaration : any): Observable<any> {
      return this.http.post<any>(environment.serverUrl + EndpointService.Declaration_Conflict, declaration, { headers: this.head }).pipe(
        map(response => {
          return response;
        })
      );
  }

  createDeclarationGift(declaration : any): Observable<any> {
      return this.http.post<any>(environment.serverUrl + EndpointService.Declaration_Gift, declaration, { headers: this.head }).pipe(
        map(response => {
          return response;
        })
      );
  }

  createDeclarationHospitality(declaration : any): Observable<any> {
      return this.http.post<any>(environment.serverUrl + EndpointService.Declaration_Hospitality, declaration, { headers: this.head }).pipe(
        map(response => {
          return response;
        })
      );
  }

  createDeclarationMeeting(declaration : any): Observable<any> {
    return this.http.post<any>(environment.serverUrl + EndpointService.Declaration_Meeting, declaration, { headers: this.head }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getAllDeclarations(filter : any): Observable<any> {
    return this.http.post<any>(environment.serverUrl + EndpointService.Declarations_All , filter, { headers: this.head }).pipe(
      map(response => { return response; })
    );
  }

  getSupervisorDeclarations(rut : string, filter : any): Observable<any> {
    return this.http.post<any>(environment.serverUrl + EndpointService.Declarations_Supervisor + rut, filter, { headers: this.head }).pipe(
      map(response => { return response; })
    );
  }

  getUserDeclarations(rut : string, filter: any){
    return this.http.post<any>(environment.serverUrl + EndpointService.Declarations + "/user/" + rut, filter, { headers: this.head }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getUserCountDeclarations(rut : string, filter: any){
    return this.http.post<any>(environment.serverUrl + EndpointService.Declarations + "/user/" + rut + "/count", filter, { headers: this.head }).pipe(
      map(response => {
        return response;
      })
    );
  }


  uploadMultiplesFiles(files : any, declarationId : number){
    var requests = [];
    for (let i = 0; i < files.length; i++){
      let file = {
        declarationId: declarationId,
        name: files[i].name,
        extension : files[i].name.substring(files[i].name.indexOf(".")+1, files[i].name.length),
        url : "",
        weight : files[i].size
      };
      requests.push(this.http.post<any>(environment.serverUrl + EndpointService.Documents, file, { headers: this.head }));
    }
    return requests;
  }

  uploadFiles(files : any, declarationId : number){
    var formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++){
      formData.append('file', files[i], files[i].name);
    }
    return  this.http.post<any>(environment.serverUrl + EndpointService.StorageDocuments + declarationId, formData, { headers: this.headMp }).pipe(
      map(response => { return response; }
    ));
  }

  uploadMultiplesStorage(files : any, declarationId : number){
    var requests = [];
    for (let i = 0; i < files.length; i++){
      const formData: FormData = new FormData();
      formData.append('file', files[i], files[i].name);
      requests.push(this.http.post<any>(environment.serverUrl + EndpointService.StorageDocuments + declarationId, formData, { headers: this.headMp }));
    }
    return requests;
  }

  checkMultipleFiles(declarationId : number){
    var filter = '{ "where" : { "declarationId": "' + declarationId + '", "order": [ "id DESC" ] }}';
    return this.http.get<any>(environment.serverUrl + EndpointService.Documents   + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => { return response; }
    ));
  }

  isRepresented(representTo : string) : Observable<any> {
    return  this.http.get<any>(environment.serverUrl + EndpointService.Representatives_isRepresented + representTo, { headers: this.headMp }).pipe(
      map(response => { return response; }
    ));
  }
}