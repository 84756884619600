import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class SessionService {
  private profile: any = null;
  private declarationYear: Boolean;
  private declarationByYear = new Subject<Boolean>();
  declarationByYear$ = this.declarationByYear.asObservable();

  private representantion = new Subject<String>();
  representantion$ = this.representantion.asObservable();
  
  constructor() {}

  public getProfile(){
    return this.profile;
  }

  public setProfile(profile : any){
    return this.profile = profile;
  }

  public setRepresentantion(rut: string){
    this.representantion.next(rut);
  }

  setDeclarationYear(declarated : Boolean){
    this.declarationYear = declarated;
  }

  getDeclarationYear(){
    return this.declarationYear;
  }

  setDeclarationByYear(declarated : Boolean){
    this.declarationByYear.next(declarated);
  }
}