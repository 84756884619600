<ngx-toasta></ngx-toasta>
<div class="page-title">
  <div class="row">
    <div class="flow-wrap col-12 mb-20">
      <div class="card card-statistics h-100 col-12 p-0">
        <div class="card-body">
          <div class="d-block d-md-flex justify-c5ntent-between">
            <div class="d-block d-sm-none">
              <h4 class="card-title border-0 pb-0">Declaraciones de mi Equipo</h4>
            </div>
            <div class="d-none d-sm-block col-4 p-0" >
              <h4 class="card-title border-0 pb-0">Declaraciones de mi Equipo</h4>
            </div>
            <div class="d-block d-md-flex clearfix sm-mt-20 mb-1"  *ngIf="(statements!= null && statements.length > 0) || statementsFiltered">
                <form [formGroup]="filterForm">
                  <div class="flow-wrap col-12 p-0">
                      <div class="form-group p-filter col-2" >
                        <label>Búsqueda</label>
                        <input type="text" class="form-control" formControlName="id" placeholder="N°" (keyup)="changeFilter()">
                      </div>
                      <div class="form-group p-filter col-3" >
                        <label>&nbsp;</label>
                        <input type="text" class="form-control" formControlName="rut" placeholder="Usuario" (keyup)="onBlurRut()">
                      </div>
                      <div class="form-group p-filter col-3" >
                        <label class="ml-04">Estado</label>
                        <select class="custom-select mr-sm-2" formControlName="status" *ngIf="statusTypes" (change)="changeFilter()">
                          <option value="0" selected>Todos</option>
                          <option *ngFor="let st of statusTypes" [value]="st.name">{{st.name}}</option>
                        </select>
                      </div>
                      <div class="form-group p-filter col-4">
                        <label class="ml-04">Tipo de Declaración</label>
                        <select class="custom-select mr-sm-2" formControlName="type" *ngIf="declarationTypes" (change)="changeFilter()">
                          <option value="0" selected>Todas</option>
                          <option *ngFor="let de of declarationTypes" [value]="de.name">{{de.name}}</option>
                        </select>
                      </div>
                    </div>
                </form>
              </div>
            </div>
          <div class="flow-wrap col-12 p-0" *ngIf="statements != null && statements.length == 0 ">
            <span>No se han encontrado declaraciones con los filtros seleccionados</span>
          </div>
          <div class="table-responsive"  *ngIf="statements != null && statements.length > 0">
            <table class="table center-aligned-table">
              <tbody>
                <tr class="bg-primary">
                  <td style="width:50px;font-weight: 500;" class="td-cap">N°</td>
                  <td style="width:120px;font-weight: 500;" class="td-cap">Usuario</td>
                  <td style="width:120px;font-weight: 500;" class="td-cap">Nombre</td>
                  <td style="width:120px;font-weight: 500;" class="td-cap">Apellido</td>
                  <td style="width:220px;font-weight: 500;" class="td-cap">Tipo de declaración</td>
                  <td style="width:290px;font-weight: 500;" class="td-cap">Plan de acción</td >
                  <td style="width:110px;font-weight: 500;" class="td-cap">Fecha de revisión</td>
                  <td style="width:110px;font-weight: 500;" class="td-cap">Estado</td>
                </tr>
                <tr *ngFor="let state of statements | paginate: config; let i = index;" class="cursor-pointer" (click)="goToDetail(state.id)">
                  <td style="width:50px;">{{state.id}}</td>
                  <td style="width:120px;">{{state.user}}</td>
                  <td style="width:120px;">{{state.firstName}}</td>
                  <td style="width:120px;">{{state.lastName}}</td>
                  <td style="width:220px;">{{state.type}}</td>
                  <td style="width:290px;" matTooltip="{{state.description}}"><div class="truncate-desc" >{{state.description}}</div></td>
                  <td style="width:110px;">{{state.nextRevision}}</td>
                  <td style="width:110px;">{{state.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="statements!= null && statements.length > 0">
          <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" class="pagination-ap text-center"
            (pageChange)="onPageChange($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
