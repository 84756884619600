import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { ConstantService } from '@services/constant.service';
import { Subject } from 'rxjs';

@Injectable()
export class ActionPlanService {
  private newActionPlan = new Subject<Boolean>();
  newActionPlan$ = this.newActionPlan.asObservable();
  declarationPerPage = 10;
  head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
  constructor(public http: HttpClient) { }


  createActionPlan(actionPlan : any): Observable<any> {
    return this.http.post<any>(environment.serverUrl + EndpointService.ActionPlans, actionPlan, { headers: this.head }).pipe(
      map(response => {
        return response;
      })
    );
  }

  closeActionPlan(actionPlan : any): Observable<any> {
    return this.http.put<any>(environment.serverUrl + EndpointService.ActionPlans + '/cerrar', actionPlan, { headers: this.head }).pipe(
      map(response => {
        return response;
      })
    );
  }

  requireHelp(declarationId : number): Observable<any> {
    return this.http.get<any>(environment.serverUrl + EndpointService.ActionPlans + "/help/" + declarationId).pipe(
      map(response => {
        return response;
      })
    );
  }

  checkMultipleActionPlans(declarations : any){
    var requests = [];
    for (let i = 0; i < declarations.length; i++){
      var filter = '{ "where" : { "declarationId": "' + declarations[i].id + '" }, "limit": 1, "order" : [ "creationDate DESC" ]}';
      requests.push(this.http.get<any>(environment.serverUrl + EndpointService.ActionPlans  + "?filter=" + encodeURIComponent(filter)));
    }
    return requests;
  }

  getLastOneActionPlan(declarationId : number): Observable<any> {
    var filter = '{ "where" : { "declarationId": "' + declarationId + '" }, "limit": "1", "order" : [ "creationDate DESC" ]}';
    return this.http.get<any>(environment.serverUrl + EndpointService.ActionPlans  + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  getActionPlans(declarationId : number): Observable<any> {
    var filter = '{ "where" : { "declarationId": "' + declarationId + '" }, "order" : [ "creationDate DESC" ]}';
    return this.http.get<any>(environment.serverUrl + EndpointService.ActionPlans  + "?filter=" + encodeURIComponent(filter)).pipe(
      map(response => {
        return response;
      })
    );
  }

  setNewActionPlan(){
    this.newActionPlan.next(true);
  }
}