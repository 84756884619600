<div class="wrapper" > 
  <!-- style="background: url(assets/images/login-bg.jpg);" -->
  <section class="height-100vh d-flex align-items-center page-section-ptb login bg-login" style="background: url(assets/images/bg-mining.jpg);">
    <div class="container">
      <div class="row justify-content-center no-gutters vertical-align">
        <div class="col-lg-4 col-md-6 bg-white">
          <div class="login-fancy pb-20 clearfix">
            <h2 class="mb-10 text-center">404</h2>
            <div class="text-center">La página solicitada no se encuentra disponible en nuestros servidores</div>
            <div class="section-field mb-10">
              <img class="img-fluid" src="assets/images/error_fixed.png" alt="">
            </div>
            <div class="section-field">
              <div class="col-xs-12 text-center">
                <a (click)="goToPage('/')" class="font-medium deco-under cursor-pointer">Volver</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
  