import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator } from '@utils/app-validators';
import { AccountService } from '@services/account.service';
import { SessionService } from '@services/session.service';
import { Router, ActivatedRoute } from '@angular/router';
import { validate, clean, format } from 'rut.js';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { I18nService } from '@services/i18n.service';
import { selectAnOptionValidator } from '@utils/app-validators';
import { RucService } from '@services/ruc.service';
import { DniArgentinaService } from '@services/dni-argentina.service';
import { environment } from "environments/environment";

interface ldapConnection{
  description : string,
  link : string,
  iconPath? : string,
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ AccountService ]
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  loginForm: FormGroup;
  basicLogin: Boolean = false;
  panelMessage: String;
  i18nTips = {
    rut : [], password: []
  };
  tips = {
    rut : "",
    password : ""
  }

  ldapConnections: Array<ldapConnection> = [];

  constructor( private toastaService: ToastaService, private toastaConfig: ToastaConfig, public formBuilder: FormBuilder, private accountSrv : AccountService, 
    private sessionService : SessionService, public router: Router, private route: ActivatedRoute, private i18n : I18nService, private rucService : RucService, private dniArgentinaService : DniArgentinaService) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
  }

  ngOnInit() {
    this.createForm();
    this.createPanelMessage();
    this.setI18n();
    this.setJoinTooltip();
    this.setLdapConnections();
    this.loginForm.controls.document.setValue("0");
    this.route.queryParams.subscribe(params => {
      this.basicLogin = (typeof params.basic !== 'undefined');
    });
  }

  private createForm(){
    this.loginForm = this.formBuilder.group({
      rut: this.formBuilder.control({value:'', disabled: false},[Validators.minLength(11), Validators.maxLength(12), Validators.required]),
      document: [{value: '0', disabled: false}, Validators.compose([Validators.required , selectAnOptionValidator])],
      password: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), 
        checkIfHaveUppercaseLetterValidator, checkIfHaveNumberValidator])
    });
  }

  private createPanelMessage(){
    this.panelMessage = "Login Message";
  }

  onFocusRut(){
    this.loginForm.controls.rut.markAsPristine();
    if (this.loginForm.controls.rut.value != ''){
      this.loginForm.controls.rut.setValue(clean(this.loginForm.controls.rut.value));
    }
  }

  
  onBlurRut() {

    let rut = this.loginForm.controls.rut.value;
    if (rut != '') {
      let optionDocument = Number(this.loginForm.controls.document.value);
  
      if (optionDocument == 1 && rut.length > 3 && validate(rut)){
        this.loginForm.controls.rut.setErrors(null);
        this.loginForm.controls.rut.setValue(format(rut));
      }else if (optionDocument == 2 && this.rucService.validaRuc(rut)){
        this.loginForm.controls.rut.setErrors(null);
      }else if (optionDocument == 3 && this.dniArgentinaService.validaDni(rut)){
        this.loginForm.controls.rut.setErrors(null);
      }else{
        this.loginForm.controls.rut.setErrors({'incorrect': true});
      }

      this.loginForm.controls.rut.markAsDirty();
    }
  }

  private setI18n(){
    this.i18nTips = {
      rut: [
        this.i18n.getKey('tips.rut1')
      ],
      password : [
        this.i18n.getKey("tips.remember"),
        this.i18n.getKey('tips.blank'),
        this.i18n.getKey("tips.pass1"),
        this.i18n.getKey("tips.pass2"),
        this.i18n.getKey("tips.pass3")
      ]
    }
  }

  private setJoinTooltip(){
    this.tips.rut = this.i18nTips.rut.join("\r\n");
    this.tips.password = this.i18nTips.password.join("\r\n");
  }

  private setLdapConnections(){
    this.ldapConnections.push({ description : 'CAP / AGUAS CAP / PLL', link : environment.serverUrl + 'users/login-sso?idp=CAP', iconPath : '/assets/images/saml2_azure.png' });
    this.ldapConnections.push({ description : 'CMP', link : environment.serverUrl + 'users/login-sso?idp=CMP', iconPath : '/assets/images/saml2_azure.png' });
    this.ldapConnections.push({ description : 'Siderúrgica Huachipato ', link : environment.serverUrl + 'users/login-sso?idp=CAPACERO', iconPath : '/assets/images/saml2_azure.png' });
  }

  doLogin(){
    let rut = this.loginForm.controls.rut.value;
    if (Number(this.loginForm.controls.document.value) == 1){
      rut = clean(this.loginForm.controls.rut.value);
    }

    this.subscription.add(this.accountSrv.authenticate(rut, this.loginForm.controls.password.value, Number(this.loginForm.controls.document.value)).subscribe(
      response => {
          this.loginForm.reset();
          this.getUserInformation();
      },
      response =>    { 
        let error = response.error.error.message.toString().split("|");
        if (error.length == 0){
          this.error('', this.i18n.getKey(response.error.error.message), '');
        } else {
          this.error('', this.i18n.getKeyWithParameters(error[0], { attempts: error[1]}), '');
        }
      },
    ));
  }

  private getUserInformation(){
    this.subscription.add(this.accountSrv.getProfile().subscribe(
      response => {
        if (response.isRepresentative == 1){
          this.router.navigate(["/representation"]);
        } else {
          this.router.navigate(["/"]);
        }
      },
      response =>    { 
        this.error('', this.i18n.getKey(response.error.error.message), '');
      },
    ));
  }

  cleanInputs(){
    this.loginForm.controls.rut.setValue("");
    this.loginForm.controls.password.setValue("");
  }

  goToPage(page : string, params: object = undefined){
    this.loginForm.reset();
    this.router.navigate([ page ], params);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  success(title : string, msg : string, duration: string){
    this.toastaService.success(this.createOptions(title, msg, duration));
  }

  info(title : string, msg : string, duration: string){
      this.toastaService.info(this.createOptions(title, msg, duration));
  }

  warning(title : string, msg : string, duration: string){
      this.toastaService.warning(this.createOptions(title, msg, duration));
  }

  error(title : string, msg : string, duration: string){
      this.toastaService.error(this.createOptions(title, msg, duration));  
  }

  private createOptions(title : string, msg : string, duration: string){
      var toastOptions: ToastOptions = {
          title: title,
          msg: msg,
          showClose: true,
          timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
          theme: ConstantService.ToasterTheme
          
      };

      return toastOptions;
  }

  clear(){
      this.toastaService.clearAll();
  }

}
