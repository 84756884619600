
<ngx-toasta></ngx-toasta>
<div class="flex-container flex-column">
  <div class="col-lg-6 col-md-8 login-fancy-bg bg">
    <app-panel-information></app-panel-information>
  </div>
  <div class="col-lg-6 col-md-8 p-0 bg-white">
    
    <!-- Confirm RUT -->
    <div class="login-fancy pb-20 clearfix" *ngIf="!changePassword">
      <h3 class="mb-10 text-center">Recuperar Clave</h3>
      <p class="mb-20 text-center">Para solicitar la recuperación de su clave, debe tener una dirección de correo registrada.</p>
      <form [formGroup]="recoverForm" class="p-20">

        <div class="d-flex justify-content-between flex-nowrap p-0">
          <div class="form-group w-100 p-0">
            <select class="custom-select mr-sm-2" formControlName="document" (change)="cleanInputs()">
              <option value="0" selected disabled>(*) Tipo de documento</option>
              <option value="1">RUT</option>
              <option value="2">RUC</option>
              <option value="3">DNI</option>
            </select>
          </div>
          <div class="form-group p-0" >
            <app-field-validation [control]="recoverForm.controls.document" ></app-field-validation>
          </div>
        </div>

        <div class="d-flex justify-content-between flex-nowrap p-0">
          <div class="form-group w-100 p-0" >
            <input autocomplete="off" class="web form-control" type="text" required formControlName="rut" placeholder="(*) Número de documento"
            (blur)="onBlurRut('rut')" (focus)="onFocusRut('rut')" minlength="8" maxlength="12">
          </div>
          <div class="form-group p-0" >
            <app-field-validation [control]="recoverForm.controls.rut" ></app-field-validation>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-nowrap p-0">
          <div class="form-group w-100 p-0" >
            <input autocomplete="off" class="web form-control" type="text" required formControlName="confirmRut" placeholder="(*) Confirmar Número de documento"
            (blur)="onBlurRut('confirmRut')" (focus)="onFocusRut('confirmRut')" minlength="8" maxlength="12">
          </div>
          <div class="form-group p-0" >
            <app-field-validation [control]="recoverForm.controls.confirmRut" ></app-field-validation>
          </div>
        </div>
        <button (click)="forgetPassword()" [disabled]="recoverForm.invalid" class="button w-100 mb-10 mt-10">Recuperar</button>
      </form>
      <div class="section-field">
        <div class="col-xs-6">
          <a (click)="goToPage('/login', { queryParams : { basic : true } })" class="font-medium deco-under cursor-pointer float-left">Acceder</a>
        </div>
        <div class="col-xs-6">
          <a (click)="goToPage('/register')" class="font-medium deco-under cursor-pointer float-right">Registrarse</a>
        </div>
      </div>
    </div>
    
    <div class="login-fancy pb-20 clearfix" *ngIf="changePassword">
      <h3 class="mb-10 text-center">Cambiar Clave</h3>
      <p class="mb-20 text-center">Para cambiar su contraseña ingrese una nueva clave.</p>
      <form [formGroup]="registerForm">
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="password form-control" type="password" autocomplete="new-password" formControlName="newPassword" placeholder="(*) Contraseña" tabindex="1"
            minlength="8" maxlength="20">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.newPassword" [tooltip]="tips.password"></app-field-validation>
          </div>
        </div>
        <div class="flow-wrap col-12 p-0">
          <div class="form-group col-10 p-0" >
            <input class="password form-control" type="password" autocomplete="new-password" formControlName="confirmNewPassword" placeholder="(*) Confirmar Contraseña" tabindex="2"
            minlength="8" maxlength="20">
          </div>
          <div class="form-group col-2 p-0" >
            <app-field-validation [control]="registerForm.controls.confirmNewPassword" [tooltip]="tips.confirmPassword"></app-field-validation>
          </div>
        </div>
        <button (click)="setPassword()" [disabled]="registerForm.invalid" class="button w-100 mb-10 mt-10" tabindex="3">Cambiar Contraseña</button>
      </form>
    </div>
  </div>
</div>

    
