import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@services/account.service';
import { validate, clean, format } from 'rut.js';
import { I18nService } from '@services/i18n.service';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { checkIfHaveNumberValidator, matchingPasswords, checkIfHaveUppercaseLetterValidator, selectAnOptionValidator } from '@utils/app-validators';
import { RucService } from '@services/ruc.service';
import { DniArgentinaService } from '@services/dni-argentina.service';
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
  providers: [ AccountService ]

})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private email: String = 'compliance@cap.cl';
  panelMessage: String;
  recoverForm: FormGroup;
  registerForm: FormGroup;
  i18nTips = {
    rut : [], rerut: []
  };
  i18nChangeTips = {
    password: [], confirmPassword: []
  };
  tips = {
    rut : "",
    rerut : "",
    password: "",
    confirmPassword: ""
  }
  changePassword:Boolean = false;
  constructor(private toastaService: ToastaService, private toastaConfig: ToastaConfig, public formBuilder: FormBuilder, 
    private accountSrv : AccountService, public router: Router, private i18n : I18nService, private rucService : RucService, private dniArgentinaService : DniArgentinaService) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
     }

  ngOnInit() {
    this.initForm();
    this.setI18n();
    this.setJoinTooltip();
    this.createPanelMessage();
  }

  private createPanelMessage(){
    this.panelMessage = "Recover Message";
  }

  private initForm(){
    this.recoverForm = this.formBuilder.group({
      rut: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
      document: [{value: '0', disabled: false}, Validators.compose([Validators.required , selectAnOptionValidator])],
      confirmRut: this.formBuilder.control({value:'', disabled: true},[Validators.required, Validators.minLength(8), Validators.maxLength(12)]),
      }, {validator: matchingPasswords('rut', 'confirmRut')}
    );
  }

  private setI18n(){
    this.i18nTips = {
      rut: [
        this.i18n.getKey('tips.rut1')
      ],
      rerut : [
        this.i18n.getKey('tips.rerut')
      ]
    }
  }

  private setJoinTooltip(){
    this.tips.rut = this.i18nTips.rut.join("\r\n");
    this.tips.rerut = this.i18nTips.rerut.join("\r\n");
  }

  onFocusRut(control : string){
    this.recoverForm.controls[control].markAsPristine();
    if (this.recoverForm.controls[control].value != '' && this.recoverForm.controls.document.value == 1){
      this.recoverForm.controls[control].setValue(clean(this.recoverForm.controls[control].value));
    }
  }

  cleanInputs(){
    this.recoverForm.controls.rut.setValue("");
    this.recoverForm.controls.confirmRut.setValue("");
  }
  
  onBlurRut(control : string) {
    if (this.recoverForm.controls[control].value != '') {
      let optionDocument = Number(this.recoverForm.controls.document.value);
      if (optionDocument == 1 && this.recoverForm.controls[control].value.length > 3 && validate(this.recoverForm.controls[control].value)){
        this.recoverForm.controls[control].setErrors(null);
        this.recoverForm.controls[control].setValue(format(this.recoverForm.controls[control].value));
        if (control == 'rut'){
          this.recoverForm.controls.confirmRut.enable();
        }
      }else if (optionDocument == 2 && this.rucService.validaRuc(this.recoverForm.controls[control].value)){
        this.recoverForm.controls[control].setErrors(null);
        this.recoverForm.controls[control].setValue(this.recoverForm.controls[control].value);
        if (control == 'rut'){
          this.recoverForm.controls.confirmRut.enable();
        }
      }else if (optionDocument == 3 && this.dniArgentinaService.validaDni(this.recoverForm.controls[control].value)){
        this.recoverForm.controls[control].setErrors(null);
        this.recoverForm.controls[control].setValue(this.recoverForm.controls[control].value);
        if (control == 'rut'){
          this.recoverForm.controls.confirmRut.enable();
        }
      }else{
        this.recoverForm.controls[control].setErrors({'incorrect': true});
        if (control == 'rut'){
          this.recoverForm.controls.confirmRut.disable();
        }
      }

      this.recoverForm.controls[control].markAsDirty();
    }
  }

  forgetPassword(){
    if (this.recoverForm.valid){
      this.subscription.add(this.accountSrv.forgotPassword(clean(this.recoverForm.controls.rut.value)).subscribe(
        response => {
          if (response.status == 5){
            this.constructorChangePassword();
          } else {
            this.recoverForm.reset();
            this.success('', this.i18n.getKeyWithParameters('acc_forgot_request', { email : response.email}), '');
            setTimeout (() => {
              this.router.navigate(['/login']);
            }, 4000);
          }
          
        },
        response =>    {
          if (response.error.error.message == 'acc_dntexist') this.error(this.i18n.getKey('acc_dntexist'), this.i18n.getKey('acc_dntexist_content'), '')
          else this.error('', this.i18n.getKeyWithParameters(response.error.error.message, { email : this.email }), '');
        },
      ));
    }
  }

  setPassword(){
    if (this.registerForm.valid){
      this.subscription.add(this.accountSrv.setPassword(clean(this.recoverForm.controls.rut.value), this.registerForm.controls.newPassword.value).subscribe(
        response => {
          this.registerForm.reset();
          this.success('', this.i18n.getKey('acc_pass_chaned'), '');
          setTimeout (() => {
            this.router.navigate(['/login']);
          }, 4000);
        },
        response =>    {
          this.error('', this.i18n.getKey(response.error.error.message), '');

        },
      ));
    }
  }

  private constructorChangePassword(){
    this.registerForm = this.formBuilder.group({
      newPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
      confirmNewPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
    }, {validator: matchingPasswords('newPassword', 'confirmNewPassword')});
    this.i18nChangeTips = {
      password : [
        this.i18n.getKey("tips.remember"),
        this.i18n.getKey('tips.blank'),
        this.i18n.getKey("tips.repass1"),
        this.i18n.getKey("tips.repass2"),
        this.i18n.getKey("tips.repass3")
      ],
      confirmPassword : [
        this.i18n.getKey("tips.confirmPassword")
      ]
    }

    this.tips.password = this.i18nChangeTips.password.join("\r\n");
    this.tips.confirmPassword = this.i18nChangeTips.confirmPassword.join("\r\n");
    this.changePassword = true;


  }

  goToPage(page : string, params: object = undefined){
    this.recoverForm.reset();
    this.router.navigate([ page ], params);
  }

  success(title : string, msg : string, duration: string){
    this.toastaService.success(this.createOptions(title, msg, duration));
  }

  info(title : string, msg : string, duration: string){
      this.toastaService.info(this.createOptions(title, msg, duration));
  }

  warning(title : string, msg : string, duration: string){
      this.toastaService.warning(this.createOptions(title, msg, duration));
  }

  error(title : string, msg : string, duration: string){
      this.toastaService.error(this.createOptions(title, msg, duration));  
  }


  private createOptions(title : string, msg : string, duration: string){
    var toastOptions: ToastOptions = {
        title: title,
        msg: msg,
        showClose: true,
        timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
        theme: ConstantService.ToasterTheme
        
    };

    return toastOptions;
}

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
