import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@services/account.service';
import { MiscService } from '@services/misc.service';
import { emailValidator, 
  selectAnOptionValidator, checkIfHaveNumberValidator, matchingPasswords, checkIfHaveUppercaseLetterValidator } from '@utils/app-validators';
import { validate, clean, format } from 'rut.js';
import { Subject } from "rxjs/Subject";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/mergeMap";
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { I18nService } from '@services/i18n.service';
import { RucService } from '@services/ruc.service';
import { DniArgentinaService } from '@services/dni-argentina.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [ AccountService, MiscService ]
})
export class RegisterComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  panelMessage: String;
  registerForm: FormGroup;
  companies: any;
  debounceTime:number = 800;
  documentIdSupervisor:string;
  i18nTips = {
    rut : [], password: [], confirmPassword: [], company : [], supervisor : [], email : [], name: [], lastName: [], secondName: []
  };
  tips = {
    rut : "",
    password : "",
    confirmPassword : "",
    company : "",
    supervisor : "",
    email : "",
    name: "",
    lastName : "",
    secondName : ""
  }
  workFlow:number = 0;
  constructor(private toastaService: ToastaService, private toastaConfig: ToastaConfig, public formBuilder: FormBuilder, private accountSrv : AccountService, 
    public router: Router, private miscService : MiscService, private i18n : I18nService, private rucService : RucService, private dniArgentinaService : DniArgentinaService) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
     }

  ngOnInit() {
    this.createPanelMessage();
    this.initForms();
    this.setI18n();
    this.setJoinTooltip();
    setTimeout (() => {
      this.getInternalCompanies();
    }, 200);
    this.registerForm.controls.document.setValue("0");
  }

  private setI18n(){
    this.i18nTips = {
      rut: [
        this.i18n.getKey('tips.rut1')
      ],
      password : [
        this.i18n.getKey("tips.remember"),
        this.i18n.getKey('tips.blank'),
        this.i18n.getKey("tips.repass1"),
        this.i18n.getKey("tips.repass2"),
        this.i18n.getKey("tips.repass3")
      ],
      confirmPassword : [
        this.i18n.getKey("tips.confirmPassword")
      ],
      company : [
        this.i18n.getKey("tips.selectCompany")
      ],
      supervisor : [
        this.i18n.getKey("tips.supervisor")
      ],
      email : [
        this.i18n.getKey("tips.email"),
      ],
      name: [ 
        this.i18n.getKey("tips.onlyLetters"),
      ],
      lastName: [
        this.i18n.getKey("tips.onlyLetters"),
      ],
      secondName : [
        this.i18n.getKey("tips.onlyLetters"),
        ]

    }
  }

  private setJoinTooltip(){
    this.tips.rut = this.i18nTips.rut.join("\r\n");
    this.tips.password = this.i18nTips.password.join("\r\n");
    this.tips.confirmPassword = this.i18nTips.confirmPassword.join("\r\n");
    this.tips.company = this.i18nTips.company.join("\r\n");
    this.tips.supervisor = this.i18nTips.supervisor.join("\r\n");
    this.tips.email = this.i18nTips.email.join("\r\n");
    this.tips.name = this.i18nTips.name.join("\r\n");
    this.tips.lastName = this.i18nTips.lastName.join("\r\n");
    this.tips.secondName = this.i18nTips.lastName.join("\r\n");

  }

  private hasSuggestions(data) : boolean {
    if ((data && data.length > 0))
      return true;
    return false;
  }

  private createPanelMessage(){
    this.panelMessage = "Register Message";
  }

  initForms(){
    this.registerForm = this.formBuilder.group({
      emailSupervisor: this.formBuilder.control({value:'', disabled: true},[Validators.required, Validators.minLength(10), Validators.maxLength(40), emailValidator]),
      rut: this.formBuilder.control({value:'', disabled: false},[Validators.required]),
      document: this.formBuilder.control([{value: '0', disabled: false}, Validators.compose([Validators.required , selectAnOptionValidator])]),
      newEmail: this.formBuilder.control({value:'', disabled: false},[Validators.minLength(10), Validators.maxLength(40), emailValidator]),
      name: this.formBuilder.control({value: '', disabled: false}, [Validators.required, Validators.minLength(3),Validators.maxLength(35)]),
      lastName: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(3), Validators.maxLength(35)]),
      lastSecondName: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(3), Validators.maxLength(35)]),
      company: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator]),
      newPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
      confirmNewPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
    }, {validator: matchingPasswords('newPassword', 'confirmNewPassword')});
  }

  onFocusRut(){
    this.registerForm.controls.rut.markAsPristine();
    if (this.registerForm.controls.rut.value != ''){
      this.registerForm.controls.rut.setValue(clean(this.registerForm.controls.rut.value));
    }
  }

  
  onBlurRut() {

    let rut = this.registerForm.controls.rut.value;
    if (rut != '') {
      let optionDocument = Number(this.registerForm.controls.document.value);
  
      if (optionDocument == 1 && rut.length > 3 && validate(rut)){
        this.registerForm.controls.rut.setErrors(null);
        this.registerForm.controls.rut.setValue(format(rut));
      }else if (optionDocument == 2 && this.rucService.validaRuc(rut)){
        this.registerForm.controls.rut.setErrors(null);
      }else if (optionDocument == 3 && this.dniArgentinaService.validaDni(rut)){
        this.registerForm.controls.rut.setErrors(null);
      }else{
        this.registerForm.controls.rut.setErrors({'incorrect': true});
      }

      this.registerForm.controls.rut.markAsDirty();
    }

  }

  cleanInputs(){
    this.registerForm.controls.rut.setValue("");
  }

  doRegister(){
    if (this.registerForm.valid){
      this.subscription.add(this.accountSrv.register(this.createRegisterObject()).subscribe(
        response => {
          this.registerForm.reset();
          this.success('', this.i18n.getKey("acc_registered"), "");
          setTimeout (() => {
            this.router.navigate(['/login']);
          }, 4000);
        },
        response =>    {
          this.error('', this.i18n.getKey(response.error.error.message) , '');
        },
      ));
    }
  }

  private createRegisterObject(){  
    
    let rutUser = Number(this.registerForm.controls.document.value) == 1 ? clean(this.registerForm.controls.rut.value) : this.registerForm.controls.rut.value;

    return {
      rut: rutUser,
      documentIdentity : Number.parseInt(this.registerForm.controls.document.value),
      email: this.registerForm.controls.newEmail.value,
      password: this.registerForm.controls.newPassword.value,
      name : this.registerForm.controls.name.value,
      lastName : this.registerForm.controls.lastName.value,
      lastSecondName : this.registerForm.controls.lastSecondName.value,
      supervisorEmail : this.registerForm.controls.emailSupervisor.value,
      company : Number.parseInt(this.registerForm.controls.company.value)
    };

  }

  private getInternalCompanies(){
    this.subscription.add(this.miscService.getInternalCompanies().subscribe(
      response => {
        this.companies = of(response);
      }, error => {
        this.companies = of([]);
      }
    ));
  }

  changeCompany(){
    if (this.registerForm.controls.company.value != '0'){
      this.registerForm.controls.emailSupervisor.enable();
    } else {
      this.registerForm.controls.emailSupervisor.disable();
    }
  }

  goToPage(page : string, params: object = undefined){
    this.registerForm.reset();
    this.router.navigate([ page ], params);
  }

  success(title : string, msg : string, duration: string){
    this.toastaService.success(this.createOptions(title, msg, duration));
  }

  info(title : string, msg : string, duration: string){
      this.toastaService.info(this.createOptions(title, msg, duration));
  }

  warning(title : string, msg : string, duration: string){
      this.toastaService.warning(this.createOptions(title, msg, duration));
  }

  error(title : string, msg : string, duration: string){
      this.toastaService.error(this.createOptions(title, msg, duration));  
  }

  private createOptions(title : string, msg : string, duration: string){
      var toastOptions: ToastOptions = {
          title: title,
          msg: msg,
          showClose: true,
          timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
          theme: ConstantService.ToasterTheme
          
      };

      return toastOptions;
  }


  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
