<div class="wrapper" [class.slide-menu]="isSlideMenu" [ngClass]="{'wrapper-loading' : loading }" *ngIf="showPage">
    <nav class="admin-header header-dark navbar navbar-default col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <!-- logo -->
      <div class="text-left navbar-brand-wrapper">
        <a class="navbar-brand brand-logo cursor-pointer p-10" routerLink="/"><img src="/assets/images/brands/{{logo}}" /></a>
        <a class="navbar-brand brand-logo-mini" href="javascript:void(0);"><img src="/assets/images/brands/{{logo}}" alt=""></a>
      </div>
      <!-- Top bar left -->
      <ul class="nav navbar-nav d-block d-sm-none">
        <li class="nav-item">
          <a id="button-toggle" class="button-toggle-nav inline-block ml-20 pull-left" href="javascript:void(0);" (click)="toggleMenu()"><mat-icon class="mat-icon-md">menu</mat-icon></a>
        </li>
      </ul>
      <ul class="nav navbar-nav mr-auto">
        <li class="nav-item">
          <app-loading *ngIf="loading"></app-loading>
          <div class="load-not" *ngIf="!loading"></div>
        </li>
      </ul>
      <ul class="nav navbar-nav mr-auto  d-none d-sm-block">
        <li class="nav-item dropdown ml-40 mt-10">
          <app-alert *ngIf="showAlert != null && !showAlert" type="warning" message="Usted no ha realizado su declaración de conflicto anual"></app-alert>
        </li>
      </ul>
      <!-- top bar right -->
      <ul class="nav navbar-nav ml-auto">
        <li class="dropdown mr-40 profile-container">
          <div class="flow-wrap col-12 p-0 h-100">
            <div class="p-0 margin-auto">
              <mat-icon class="mat-icon-lg user-icon-header text-white cursor-pointer" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">account_circle</mat-icon> 
            </div>
            <div class="ml-10 p-0">
              <span class="text-white d-user cursor-pointer d-none d-sm-block">{{profile.firstName}} {{profile.lastName}}</span>
              <span class="d-represent d-none d-sm-block cursor-pointer" *ngIf="fullnameSelected != ''">Representando a {{ fullnameSelected }}</span>
              <span class="text-white d-none d-sm-block cursor-pointer" *ngIf="profile.mailSupervisor != ''">Supervisor: {{ profile.mailSupervisor }}</span>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-header">
                  <div class="media d-block">
                    <div class="media-body">
                      <h6 class="mt-0 mb-0">{{profile.firstName}} {{profile.lastName}}</h6>
                      <div class="fs14">{{profile.email}}</div>
                    </div>
                  </div>
                </div>
                <div class="dropdown-divider" *ngIf="representatives != null"></div>
                <div class="controls rep-container" *ngIf="representatives != null">
                  <div class="change-identity mb-2">Cambiar Cuenta </div>
                  <label class="radio w-100"> 
                    <input type="radio" [checked]="rutSelected == profile.rut" id="{{profile.rut}}" name="confirmAccount" value="{{profile.rut}}" (click)="confirmUser(profile.rut)" class="isRepresentation"/> {{profile.firstName}} {{profile.lastName}} {{ profile.secondLastName }}
                  </label>
                  <label class="radio w-100" *ngFor="let representative of representatives">
                      <input type="radio" [checked]="rutSelected == representative.rut" id="{{representative.rut}}" name="confirmAccount" value="{{representative.rut}}" class="rd"  (click)="confirmUser(representative.rut)" /> {{ representative.firstName }}  {{ representative.lastName }} {{ representative.secondLastName }} 
                  </label>
                </div>
      
                <div class="dropdown-divider"></div>
                <a class="dropdown-item cursor-pointer" (click)="logout()"><div><mat-icon class="mat-icon-sm">https</mat-icon> <span class="v-middle ml-10">Desconectar</span></div></a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  
    <!--=================================
     header End-->
    <!--=================================
     Main content -->
  
    <div class="container-fluid">
      <div class="row">
        <!-- Left Sidebar -->
        <div class="side-menu-fixed light-side-menu">
          <div class="scrollbar side-menu-bg">
            <ul class="nav n  avbar-nav side-menu">
              <!-- menu item Dashboard-->
              <li>
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">dashboard</mat-icon>
                  <div class="margin-auto-0"><a (click)="goToPage('/')" routerLinkActive="active" class="p-0 cursor-pointer text-white">Mis Declaraciones</a></div>
                </div>
              </li>
              <li *ngIf="profile.roleId == 4 || profile.roleId == 3  || profile.roleId == 2">
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">supervised_user_circle</mat-icon>
                  <div class="margin-auto-0"><a (click)="goToPage('/team')" routerLinkActive="active" class="p-0 cursor-pointer text-white">Declaraciones de mi Equipo</a></div>
                </div>
              </li>
              <li *ngIf="profile.roleId == 4 || profile.roleId == 3">
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">search</mat-icon>
                  <div class="margin-auto-0"><a (click)="goToPage('/search')" routerLinkActive="active" class="p-0 cursor-pointer text-white">Buscar Declaraciones</a></div>
                </div>
              </li>
              <li *ngIf="profile.roleId == 4 || profile.roleId == 3">
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">bar_chart</mat-icon>
                  <div class="margin-auto-0"><a class="p-0 cursor-pointer text-white" (click)="subMenu.reportability = !subMenu.reportability">Reportabilidad</a></div>
                  <ul class="collapse show mt-10" *ngIf="subMenu.reportability">
                    <li> <a (click)="goToPage('/reportability/conflicts')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Conflicto de Interés</a> </li>
                    <li> <a (click)="goToPage('/reportability/meetings')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Reuniones</a> </li>
                    <li> <a (click)="goToPage('/reportability/gifts')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Regalos y Hospitalidades</a> </li>
                    <li> <a (click)="goToPage('/reportability/general')" routerLinkActive="active" class="cursor-pointer" *ngIf="profile.roleId == 4"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Generales</a> </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="profile.roleId == 4 || profile.roleId == 5">
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">settings</mat-icon>
                  <div class="margin-auto-0"><a (click)="goToPage('/user-manager')" routerLinkActive="active" class="p-0 cursor-pointer text-white">Gestor de Usuarios</a></div>
                </div>
              </li>
              <li>
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">note_add</mat-icon>
                  <div class="margin-auto-0"><a class="p-0 cursor-pointer text-white" (click)="subMenu.declaration = !subMenu.declaration">Declaración</a></div>
                  <ul class="collapse show mt-10" *ngIf="subMenu.declaration">
                    <li> <a (click)="goToPage('/statement/add/1')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Conflicto de Interés</a> </li>
                    <li> <a (click)="goToPage('/statement/add/2')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Regalos y Hospitalidades</a> </li>
                    <li> <a (click)="goToPage('/statement/add/3')" routerLinkActive="active" class="cursor-pointer"><mat-icon class="mat-icon-sm">keyboard_arrow_right</mat-icon> Reuniones</a> </li>
                  </ul>
                </div>
              </li>
              <li>
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">help</mat-icon>
                  <div class="margin-auto-0"><a href="/faq" target="_blank" routerLinkActive="active" class="p-0 cursor-pointer text-white">Terminología</a></div>
                </div>
              </li>
               <li>
                <div class="flow-wrap col-12 p-10 menu-new">
                  <mat-icon class="mat-icon-sm menu-item">new_releases</mat-icon>
                  <div class="margin-auto-0"><a href="https://compliance.cap.cl/" target="_blank" routerLinkActive="active" class="p-0 cursor-pointer text-white">CAP Integridad</a></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
  
        <!--=================================
         Main content -->
        <!--=================================
        wrapper -->
  
         <div class="content-wrapper">
          <router-outlet></router-outlet>
          <!--<footer class="bg-white p-4">
            <div class="row">
              <div class="col-md-6">
                <div class="text-center text-md-left">
                    <p class="mb-0"> &copy; Copyright <span id="copyright">{{year}}</span> Compliance. Todos los derechos reservados. </p>
                </div>
              </div>
              <div class="col-md-6">
                <ul class="text-center text-md-right">
                  <li class="list-inline-item"><a href="https://compliancecap.koomedia.cl/" target="_blank">Portal Compliance </a> </li>
                </ul>
              </div>
            </div>
          </footer>-->
      </div>
    </div>
  </div>
</div>