import { Component, HostListener } from '@angular/core';
import { I18nService } from '@services/i18n.service';
import { UtilService } from '@services/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app-compliance';
  theme = 'cap';
  screenType:string;

  constructor(private i18nService: I18nService, private utilSrv : UtilService){
    this.i18nService.setLanguages();
    this.i18nService.detectBestUserLanguage(navigator.language); 
    this.checkScreenType(window.innerWidth);
  }

  checkScreenType(screenWidth:number){
    if (screenWidth < 576){
      this.screenType = 'xs';
    } else if (screenWidth >= 576 && screenWidth < 768){
      this.screenType = 'sm';
    }  else if (screenWidth >= 768 && screenWidth < 992){
      this.screenType = 'md';
    } else if (screenWidth >= 992 && screenWidth < 1200){
      this.screenType = 'lg';
    } else{
      this.screenType = 'xl';
    }
    this.utilSrv.setScreenType(this.screenType);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenType(window.innerWidth);
  }
  
}
