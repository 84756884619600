<div class="page-title"> 
    <div class="row">
        <div class="flow-wrap col-12 mb-10">
            <div class="card card-statistics h-100 col-12 p-0">
                <div class="card-body">
                    <div  class="flow-wrap col-12 p-0 header-title"> 
                        <div class="col-xl-8 p-0" >
                            <h4 class="h5-title">Terminología</h4>
                        </div>
                        <div class="col-xl-4 p-0" [ngClass]="{'text-right' : screenType != 'xs'}">
                            <a href="{{downloadLink}}" target="_blank" class="link-compliance cursor-pointer">Descargar Documento</a>
                        </div>
                    </div>  
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Conflicto de Interés</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">Ocurre cuando uno o más intereses personales de nuestros directores, ejecutivos, profesionales y trabajadores, podrían afectar los intereses 
                            del Grupo CAP, de forma tal que la existencia de dicho conflicto podría incentivar al trabajador a privilegiar su interés personal, o el 
                            de terceros relacionados con él, por sobre el interés del Grupo CAP, al momento de tomar una decisión que le corresponda en función de su cargo.
                        </p>
                    </div>  
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Conflicto de Interés Real</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Se produce cuando un trabajador del Grupo CAP tiene un interés particular respecto de un negocio de la empresa en el que deba participar, en función de su cargo.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Conflicto de Interés Potencial</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Se produce cuando un trabajador del Grupo CAP tiene un interés particular que podría influir a la hora de hacer un juicio profesional desde la posición o cargo que ocupa.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Conflicto de Interés Percibido</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            También conocido como aparente; se genera cuando, si bien el trabajador del Grupo CAP no tiene un conflicto de interés -ni real ni potencial-, hay apariencia de que el éste existe, por lo que un tercero podría llegar a concluir que hay un conflicto.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Personas Políticamente Expuesta</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Se incluyen en esta categoría a jefes de Estado o de un Gobierno, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, 
                            altos ejecutivos de empresas estatales, así como sus cónyuges, sus parientes hasta el segundo grado de consanguinidad (hermanos, abuelos y nietos).
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Persona Influyente</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Es aquella persona que tiene autoridad o poder, generalmente por elección popular y que eventualmente pudiera usarlo en beneficio, a favor o en contra, 
                            de alguna empresa del Grupo CAP. Por ejemplo, presidentes de asociaciones gremiales, presidentes de juntas de vecinos o líderes sindicales.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Personas que representan a otras</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Es posible que en el ejercicio de su cargo en Grupo Personas que representan a otras personas, es decir que ejecutan actividades en nombre de otra entidad de personas o bien, que asumen deberes de lealtad o confianza de un grupo más amplio de personas, a partir de una ley, orden de autoridad o de un acto o contrato. Por ejemplo, considere dentro de este grupo a: Líderes comunitarios, Líderes Indígenas, Líderes Sindicales, Jefes de Grupos de Interés y sus agentes o representantes.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Funcionarios o empleado público</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Toda persona que desempeñe un cargo o función pública, sea en la administración central o en instituciones o empresas semifiscales, municipales, autónomas u organismos creados por el Estado o dependientes de éste aunque no sean de nombramiento del Presidente de la República ni reciban sueldo del Estado, incluyéndose aquellos cargos de elección popular. El eje central de la definición legal es el desempeño de un cargo o función pública.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Funcionario o empleado público extranjero</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            La misma definición anterior es válida para los funcionarios públicos extranjeros, pudiendo ejercer sus funciones en territorio nacional o en país extranjero
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Regalos</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            En algunas culturas, y SOLO en ciertas ocasiones, dar o recibir regalos es una parte habitual de hacer negocios. Para estas circunstancias justificadas de intercambio de regalos, recuerde las siguientes reglas: el monto límite asignado para regalos, beneficios y hospitalidades de negocios es de US$60 o 1,5 UF. 
                            <br><br>
                            Se permiten los regalos, dentro del límite monetario referido, cuando se trate de objetos de escaso valor que se entregan típicamente con fines de marketing o protocolares, por ejemplo, lápices y/o cuadernos que contengan el logo de una empresa.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Supervisor</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Quien ejerce de línea directa de supervisión de quien este declarando.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Hospitalidades</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Hospitalidad habitual de negocios: es aquella hospitalidad importante para el negocio, la cual deriva de actividades relevantes ofrecidas por el proveedor actual o potencial. Por ejemplo: asistir a conferencias, asistir a una feria comercial, participar en sesiones de capacitación respecto del uso de una máquina en particular o una inspección de equipamiento pesado en la casa matriz. Siempre deberá ser aprobada por el supervisor directo, y dicha aprobación debe constar por escrito y ser anterior a la entrega o recepción. 
                            <br><br>
                            Hospitalidad que no es de negocios: es aquella no considerada relevante para el negocio. Por ejemplo: conciertos musicales, eventos deportivos o eventos culturales. En las ocasiones como las señaladas no existe un claro vínculo de negocios. Cualquier hospitalidad que no es de negocios debe tratarse como un regalo, es decir, sólo debe aceptarse luego de ser aprobada por el supervisor directo, y siempre que tenga una real justificación de negocios
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Viajes de Negocios</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Se incluyen dentro de las hospitalidades los viajes que se realizan por trabajadores del Grupo CAP y que son financiados por terceros, típicamente para visitar y conocer tecnología, bienes o equipamiento que pueda ser de interés para la empresa.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Comidas de Negocio</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Comúnmente se consideran importantes para el negocio y por ende aceptables. Sin embargo, éstas deben cumplir ciertos requisitos para que no sean consideradas riesgosas. En particular, las comidas nunca deben ser excesivamente lujosas ni ostentosas.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>
                    
                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Relación de parentesco</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Relación que mantiene quien declara con una persona que participa en una empresa o sociedad que actualmente es, o a
                            futuro puede ser, proveedor, contratista, donatario, cliente, competidor, entre otros, de alguna empresa del Grupo
                            CAP.<br>
                        
                            Las relaciones que deben declararse son las siguientes:<br><br>
                        
                            - Cónyuge o conviviente civil;<br>
                        
                            - Parientes en cualquier grado de la línea recta, ascendientes y/o descendientes (por ejemplo, padre, madre,
                            abuelos(as), hijos(as), nietos(as), bisnietos(as)).<br>
                        
                            - Parientes colaterales por consanguinidad hasta el tercer grado inclusive (hermanos(as), tíos(as), sobrinos(as),
                            primos(as)).<br>
                        
                            - Parientes por afinidad, es decir, la familia del cónyuge, también hasta el tercer grado inclusive (suegros(as),
                            yerno, nuera, cuñados(as)).<br>
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>


                    <div  class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Relación de gestión</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Relación que tiene lugar cuando quien declara participa como director, administrador o gerente de sociedades o empresas que actualmente están, o a futuro podrían estar, relacionadas con alguna de las empresas del Grupo CAP, en calidad de proveedoras, contratistas, clientes, competidores, entre otros.
                        </p>
                    </div> 
                    <div class="dropdown-divider mb-20"></div>


                    <div class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Relación de propiedad</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Relación que tiene lugar cuando quien declara es dueño, controlador, socio o accionista de una sociedad que
                            actualmente es, o a futuro pueda ser, proveedor, contratista, cliente, competidor, entre otros, de alguna
                            empresa del Grupo CAP, ya sea directamente o a través de algún pariente. En este último caso, deben considerarse
                            los parientes incorporados en la definición de relación de parentesco.<br>
                    
                            Con respecto a la calidad de socio o accionista, ya sea de quien declara o de un pariente suyo, sólo deben
                            considerarse aquellos casos es que se cuente con más del 10% de participación.
                        </p>
                    </div>
                    <div class="dropdown-divider mb-20"></div>

                    <div class="flow-wrap col-12 p-0 mb-10">
                        <div class="faq-title">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <div>Relación laboral</div>
                        </div>
                        <p [ngClass]="{'faq-desc' : screenType != 'xs'}">
                            Relación que tiene lugar cuando quien declara ha prestado servicios a una persona natural o jurídica que actualmente es, o a futuro pueda ser, proveedor, contratista, cliente, competidor, entre otros, de alguna empresa del Grupo CAP. Se entenderá que una vez transcurridos 18 meses desde el cese de la prestación de servicios en la persona natural o jurídica interesada, ya no existe este conflicto de interés.
                        </p>
                    </div>
                    <div class="dropdown-divider mb-20"></div>
                </div>
            </div>
        </div>
    </div>
</div>
