/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-page-public.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./master-page-public.component";
import * as i5 from "../../../services/util.service";
var styles_MasterPagePublicComponent = [i0.styles];
var RenderType_MasterPagePublicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterPagePublicComponent, data: {} });
export { RenderType_MasterPagePublicComponent as RenderType_MasterPagePublicComponent };
export function View_MasterPagePublicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "wrapper-loading": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "section", [["class", "height-100vh d-flex align-items-center page-section-ptb login bg-login"], ["style", "background: url(assets/images/bg-mining.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wrapper"; var currVal_1 = _ck(_v, 3, 0, _co.loading); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 7, 0); }, null); }
export function View_MasterPagePublicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-master-page-public", [], null, null, null, View_MasterPagePublicComponent_0, RenderType_MasterPagePublicComponent)), i1.ɵdid(1, 114688, null, 0, i4.MasterPagePublicComponent, [i5.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasterPagePublicComponentNgFactory = i1.ɵccf("app-master-page-public", i4.MasterPagePublicComponent, View_MasterPagePublicComponent_Host_0, {}, {}, []);
export { MasterPagePublicComponentNgFactory as MasterPagePublicComponentNgFactory };
