import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {
  public config: SwiperConfigInterface = { };
  brands: any = [];
  constructor() { }

  ngOnInit() {
    this.buildCarousel();
  }

  private buildCarousel(){
    this.brands.push({ name: 'CAP', file : 'cap.png'})
    this.brands.push({ name: 'CMP', file : 'cmp.png'})
    this.brands.push({ name: 'Huachipato', file : 'huachipato.png'})
    this.brands.push({ name: 'Cintac', file : 'cintac.png'})
    this.brands.push({ name: 'Sehover', file : 'sehover.png'})
    this.brands.push({ name: 'Tupemesa', file : 'tupemesa.png'})
    this.brands.push({ name: 'Calaminon', file : 'calaminon.png'})
    this.brands.push({ name: 'Tubos Argentinos', file : 'tasa.png'})
    this.brands.push({ name: 'Puerto Las Losas', file : 'ppl.png'})
    this.brands.push({ name: 'Aguas CAP', file : 'cap_aguas.png'})
    this.brands.push({ name: 'Imopac', file : 'imopac.png'})
    this.brands.push({ name: 'Promet', file : 'promet.png'})
    this.brands.push({ name: 'Atom', file : 'attom.png'})
  }
  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,         
      keyboard: true,
      navigation: false,
      pagination: false,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide",
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        480: {
          slidesPerView: 1
        },
        600: {
          slidesPerView: 1,
        },
        960: {
          slidesPerView: 1,
        },
        1280: {
          slidesPerView: 1,
        },
        1500: {
          slidesPerView: 1,
        }
      }
    }
  }

}
