<div class="brands-carousel" *ngIf="brands.length > 0">
    <div class="swiper-container" [swiper]="config">
        <div class="swiper-wrapper">      
            <div *ngFor="let brand of brands" class="swiper-slide">
                <div class="p-0">
                    <img src="/assets/images/brands/{{brand.file}}" class="swiper-lazy">
                </div>
            </div>
        </div> 
    </div>
</div>
