import { Component, OnInit } from '@angular/core';
import { UtilService } from '@services/util.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '@services/session.service';
import { Router } from '@angular/router';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { AccountService } from '@services/account.service';
import { DeclarationService } from '@services/declaration.service';
import { UserManagerService } from '@services/user-manager.service';
import { forkJoin } from 'rxjs';
import { format } from 'rut.js';

@Component({
  selector: 'app-master-page-private',
  templateUrl: './master-page-private.component.html',
  styleUrls: ['./master-page-private.component.scss'],
  providers : [ AccountService, UserManagerService, DeclarationService ]
})
export class MasterPagePrivateComponent implements OnInit {
    private subscription: Subscription = new Subscription();
    public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark'}
    profile: any;
    representatives : any = null;
    rutSelected:string;
    fullnameSelected:string = '';
    isSearchActive:boolean = false;
    isSlideMenu:boolean = false;
    progressBar$: any;
    loading : Boolean;
    showPage:Boolean = false;
    subMenu = {
        declaration :false,
        reportability: false
    }
    showAlert: Boolean = null;
    year: string;
    logo:string = "";
    constructor(private accountSrv : AccountService, private utilService : UtilService, private sessionService : SessionService, private router : Router,
        private toastaService: ToastaService, private toastaConfig: ToastaConfig, private usrMgrService : UserManagerService, 
        private declartionSrv : DeclarationService) { 
        this.toastaConfig.theme = 'material';
        this.toastaConfig.position = 'top-center';
    }

    ngOnInit() {
        this.year = new Date().getFullYear().toString();
        this.profile = this.sessionService.getProfile();
        this.checkLogo();
        this.subscription.add(this.utilService.progressBar$.subscribe(
            load => { 
            this.loading = load
            }
        ));

        this.subscription.add(this.sessionService.declarationByYear$.subscribe(
            load => { 
                this.getDeclarationConflictByYear();
            }
        ));
        
        this.getDeclarationConflictByYear();
        if (this.profile.isRepresentative == 1) { 
            this.checkRepresentative(); 
        } else { 
            this.showPage = true; 
        }
    }

    private checkLogo(){
        if (this.profile.internalCompany == 1){
            this.logo = "cap.png";
        }

        if (this.profile.internalCompany == 2){
            this.logo = "cmp.png";
        }

        if (this.profile.internalCompany == 3){
            this.logo = "huachipato.png";
        }

        if (this.profile.internalCompany == 4){
            this.logo = "cintac.png";
        }

        if (this.profile.internalCompany == 5){
            this.logo = "signovial.png";
        }

        if (this.profile.internalCompany == 6){
            this.logo = "sehover.png";
        }

        if (this.profile.internalCompany == 7){
            this.logo = "tupemesa.png";
        }
        if (this.profile.internalCompany == 8){
            this.logo = "calaminon.png";
        }
        if (this.profile.internalCompany == 9){
            this.logo = "tasa.png";
        }
        if (this.profile.internalCompany == 10){
            this.logo = "ppl.png";
        }
        if (this.profile.internalCompany == 11){
            this.logo = "cap_aguas.png";
        }
        if (this.profile.internalCompany == 12){
            this.logo = "imopac.png";
        }
        if (this.profile.internalCompany == 23){
            this.logo = "promet.png";
        }
        if (this.profile.internalCompany == 27){
            this.logo = "attom.png";
        }
    }

    private checkRepresentative(){
        if (localStorage.getItem("representation")){
            setTimeout (() => {
                this.getRepresentatives();
            }, 200);
        } else {
            this.router.navigate(['/representation']);
        }
    }

    private getDeclarationConflictByYear(){
        this.subscription.add(this.declartionSrv.getDeclarationConflictByYear(this.profile.rut).subscribe(
            response => {
                this.showAlert = response;
            },
            response =>    { 
                this.showAlert = false;
            },
        )); 
    }

    private getRepresentatives(){
        this.subscription.add(this.accountSrv.getRepresentatives(this.profile.rut).subscribe(
            response => {
                this.getUsers(response);
            },
            response =>    { 
                this.representatives = [];
            },
        ));
    }

    private getUsers(representatives : string){
        forkJoin(this.usrMgrService.getUsersByRUt(representatives)).subscribe(results => {
          this.rutSelected = localStorage.getItem("representation");
          this.representatives = results;
          if (this.rutSelected != this.profile.rut){
            this.getRepresentFullname();
          } 
          this.showPage = true;
        });
      }

    getRepresentFullname(){
        let selected = this.representatives.filter( row => {
          return row.rut == this.rutSelected;
        });
        this.fullnameSelected = selected[0].firstName + " " + selected[0].lastName;
    }

    error(title : string, msg : string, duration: string){
        this.toastaService.error(this.createOptions(title, msg, duration));  
    }

    confirmUser(user : string){
        localStorage.setItem("representation", user);
        this.rutSelected = localStorage.getItem("representation");
        this.sessionService.setRepresentantion(localStorage.getItem("representation"));
        if (this.rutSelected == this.profile.rut){
            this.fullnameSelected = '';
        } else {
            this.getRepresentFullname();
            this.registerAuditAction();
        }
      }
    
    private registerAuditAction(){
        this.subscription.add(this.accountSrv.confirmRepresentation(this.profile.rut, localStorage.getItem("representation")).subscribe(
          response => {},
          error =>    {},
        ));
      }

    private createOptions(title : string, msg : string, duration: string){
        var toastOptions: ToastOptions = {
            title: title,
            msg: msg,
            showClose: true,
            timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
            theme: ConstantService.ToasterTheme
            
        };

        return toastOptions;
    }
    
    logout(){
        localStorage.removeItem("token")
        localStorage.removeItem("representation");
        this.router.navigate(['/login']);
    }

    formatRut(rut : string){
        return format(rut);
    }

    goToPage(page : string){
        if (window.innerWidth < 500){
            this.toggleMenu();
        }
        this.router.navigate([page]);
    }

    toggleSearch(){
        this.isSearchActive = !this.isSearchActive;
    }

    toggleMenu(){
        this.isSlideMenu = !this.isSlideMenu;
    }

    toggleFullscreen(elem) {
        elem = elem || document.documentElement;
        if (!document['fullscreenElement'] && !document['mozFullScreenElement'] &&
            !document['webkitFullscreenElement'] && !document['msFullscreenElement']) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element['ALLOW_KEYBOARD_INPUT']);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document['webkitExitFullscreen']) {
                document['webkitExitFullscreen']();
            }
        }
    }
}
