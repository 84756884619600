import { Subject } from 'rxjs';
import { Chart } from 'chart.js';
import * as i0 from "@angular/core";
export class UtilService {
    constructor() {
        this.screenType = new Subject();
        this.screenType$ = this.screenType.asObservable();
        this.progressBar = new Subject();
        this.progressBar$ = this.progressBar.asObservable();
    }
    showProgressBar(visible) {
        this.progressBar.next(visible);
    }
    setScreenType(screen) {
        this.setScreenSize(screen);
        this.screenType.next(screen);
    }
    setScreenSize(screen) {
        this.size = screen;
    }
    getScreenSize() {
        return this.size;
    }
    formatDate(date) {
        var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [day, month, year].join('-');
    }
    /* Filter array by string
    *
    * @param mainArr
    * @param searchText
    * @returns {any}
    */
    static filterArrayByString(mainArr, searchText) {
        if (searchText === '') {
            return mainArr;
        }
        searchText = searchText.toLowerCase();
        return mainArr.filter(itemObj => {
            return this.searchInObj(itemObj, searchText);
        });
    }
    /**
     * Search in object
     *
     * @param itemObj
     * @param searchText
     * @returns {boolean}
     */
    static searchInObj(itemObj, searchText) {
        for (const prop in itemObj) {
            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }
            const value = itemObj[prop];
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }
            else if (Array.isArray(value)) {
                if (this.searchInArray(value, searchText)) {
                    return true;
                }
            }
            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }
    /**
     * Search in array
     *
     * @param arr
     * @param searchText
     * @returns {boolean}
     */
    static searchInArray(arr, searchText) {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }
            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }
    /**
     * Search in string
     *
     * @param value
     * @param searchText
     * @returns {any}
     */
    static searchInString(value, searchText) {
        return value.toLowerCase().includes(searchText);
    }
    /**
     * Generate a unique GUID
     *
     * @returns {string}
     */
    static generateGUID() {
        function S4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return S4() + S4();
    }
    /**
     * Toggle in array
     *
     * @param item
     * @param array
     */
    static toggleInArray(item, array) {
        if (array.indexOf(item) === -1) {
            array.push(item);
        }
        else {
            array.splice(array.indexOf(item), 1);
        }
    }
    /**
     * Handleize
     *
     * @param text
     * @returns {string}
     */
    static handleize(text) {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(/[^\w\-]+/g, '') // Remove all non-word chars
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    }
    showGraphic(chartId, obj, title) {
        var ctx = document.getElementById(chartId).getContext('2d');
        var chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: [],
                datasets: [{
                        data: [],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)'
                        ],
                        borderWidth: 1
                    }]
            },
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: title,
                    fontColor: "#2f4f4f",
                    fontSize: 20,
                    padding: 10,
                    margin: 15,
                    backgroundColor: "#FFFFE0",
                    borderThickness: 1,
                    cornerRadius: 5,
                    fontWeight: "bold"
                },
                scales: {
                    yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1
                            }
                        }]
                }
            }
        });
        for (var i in obj) {
            chart.config.data.labels.push(obj[i].label);
            chart.config.data.datasets[0].data.push(obj[i].value);
        }
        chart.update();
    }
}
UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(); }, token: UtilService, providedIn: "root" });
