<div class="flex-container flex-column">
  <div class="col-lg-4 col-md-6 login-fancy-bg bg">
    <app-panel-information></app-panel-information>
  </div>
  <div class="col-lg-4 col-md-6 row p-0">
    <div class="col-12 p-20 bg-white d-flex flex-column justify-content-center align-items-center" *ngIf="!showError">
        <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
        <h4 class="mt-20">Redirigiendo...</h4>
    </div>
    <div class="col-12 p-20 bg-white d-flex flex-column justify-content-center align-items-center" *ngIf="showError">
      <h4 class="mt-20">Falta Token</h4>
      <a [href]="origin" class="btn btn-danger mt-20">Volver a Inicio de Sesión</a>
  </div>    
  </div>
</div>