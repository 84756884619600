import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { I18nService } from '@services/i18n.service';
import { AccountService } from '@services/account.service';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkIfHaveNumberValidator, matchingPasswords, checkIfHaveUppercaseLetterValidator } from '@utils/app-validators';

@Component({
  selector: 'app-activate-password',
  templateUrl: './activate-password.component.html',
  styleUrls: ['./activate-password.component.scss'],
  providers : [ AccountService ]
})
export class ActivatePasswordComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  rut: string;
  token : string;
  registerForm: FormGroup;
  showPage: Boolean = false;
  i18nTips = {
    password: [], confirmPassword: []
  };
  tips = {
    password: "",
    confirmPassword: ""
  }
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public formBuilder: FormBuilder,
    private accountSrv : AccountService, private i18n : I18nService, private toastaService: ToastaService, private toastaConfig: ToastaConfig) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
     }

  ngOnInit() {
    this.subscription.add(this.activatedRoute.params.subscribe(
      params => {
        this.token = params['hash'];
        if (this.token && this.token != ''){
          setTimeout (() => {
            this.checkHash();
          }, 200);
        } else {
          this.router.navigate(['/login']);
        }
      }
    ));
  }

  private checkHash(){
    this.subscription.add(this.accountSrv.validateHashForgotPassword(this.token).subscribe(
      response => {
        this.rut = response;
        this.rut = this.rut.toString();
        this.constructorChangePassword();
        
      },
      response =>    {
        this.error('', this.i18n.getKey(response.error.error.message), '');
        setTimeout (() => {
          this.router.navigate(['/login']);
        }, 4000);
      },
    ));
  }

  private constructorChangePassword(){
    this.registerForm = this.formBuilder.group({
      newPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
      confirmNewPassword: this.formBuilder.control({value:'', disabled: false},[Validators.required, Validators.minLength(8), Validators.maxLength(20), checkIfHaveNumberValidator, checkIfHaveUppercaseLetterValidator]),
    }, {validator: matchingPasswords('newPassword', 'confirmNewPassword')});
    this.i18nTips = {
      password : [
        this.i18n.getKey("tips.remember"),
        this.i18n.getKey('tips.blank'),
        this.i18n.getKey("tips.repass1"),
        this.i18n.getKey("tips.repass2"),
        this.i18n.getKey("tips.repass3")
      ],
      confirmPassword : [
        this.i18n.getKey("tips.confirmPassword")
      ]
    }

    this.tips.password = this.i18nTips.password.join("\r\n");
    this.tips.confirmPassword = this.i18nTips.confirmPassword.join("\r\n");
    this.showPage = true;
  }

  updatePassword(){
    if (this.registerForm.valid){
      this.subscription.add(this.accountSrv.updatePassword(this.rut, this.registerForm.controls.newPassword.value, this.token).subscribe(
        response => {
          this.registerForm.reset();
          this.success('', this.i18n.getKey('acc_pass_chaned'), '');
          setTimeout (() => {
            this.router.navigate(['/login']);
          }, 4000);
        },
        response =>    {
          this.error('', this.i18n.getKey(response.error.error.message), '');

        },
      ));
    }
  }

  success(title : string, msg : string, duration: string){
    this.toastaService.success(this.createOptions(title, msg, duration));
  }

  info(title : string, msg : string, duration: string){
      this.toastaService.info(this.createOptions(title, msg, duration));
  }

  warning(title : string, msg : string, duration: string){
      this.toastaService.warning(this.createOptions(title, msg, duration));
  }

  error(title : string, msg : string, duration: string){
      this.toastaService.error(this.createOptions(title, msg, duration));  
  }

  private createOptions(title : string, msg : string, duration: string){
      var toastOptions: ToastOptions = {
          title: title,
          msg: msg,
          showClose: true,
          timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
          theme: ConstantService.ToasterTheme
          
      };

      return toastOptions;
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
