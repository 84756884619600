import { environment } from 'environments/environment';
import { Subscription } from 'rxjs/Subscription';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class I18nService {
    constructor(translateService) {
        this.translateService = translateService;
        this.subscription = new Subscription();
        this.flags = [
            { code: 'es', name: 'Español' }
            /*{ code: 'en', name:'English' },
            { code: 'pt', name:'Portuguese' },
            { code: 'de', name:'Deutsch' },
            { code: 'fr', name:'Français' }*/
        ];
    }
    ;
    /**
   * @author Carlos Castañeda
   * @description I18nService.setLanguages es un metodo publico que se encarga de
   * registrar los lenguajes para internationalization del sitio
   * @param
   * @returns void
   */
    setLanguages() {
        let langs = [];
        for (let lang in this.flags) {
            langs.push(lang['code']);
        }
        this.translateService.addLangs(langs);
    }
    getFlagsForLanguage() {
        return this.flags;
    }
    /**
  * @author Carlos Castañeda
  * @description I18nService.detectBestUserLanguage es un metodo publico que se encarga de
  * capturar el lenguaje desde el header de la peticion, si no se encuentra se obtiene desde los properties
  * @param
  * @returns void
  */
    detectBestUserLanguage(language) {
        /** Descomentar solo si hay multilenguaje */
        /*let headerLanguage = language.split('-')[0];
        if (headerLanguage){
          this.translateService.setDefaultLang(headerLanguage);
        }else{
          this.translateService.setDefaultLang(environment.language);
        }*/
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(this.translateService.getDefaultLang());
    }
    getKeyWithParameters(literal, parameters) {
        let message;
        this.subscription = this.translateService.get(literal, parameters).subscribe((response) => { message = response; }, (error) => { message = ""; });
        return message;
    }
    getKey(literal) {
        let message;
        this.subscription = this.translateService.get(literal).subscribe((response) => { message = response; }, (error) => { message = ""; });
        return message;
    }
}
I18nService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function I18nService_Factory() { return new I18nService(i0.ɵɵinject(i1.TranslateService)); }, token: I18nService, providedIn: "root" });
