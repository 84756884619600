import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, } from 'rxjs/Subscription';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { ActionPlanService } from '@services/action-plan.service';

@Component({
  selector: 'app-action-plan-comment',
  templateUrl: './action-plan-comment.component.html',
  styleUrls: ['./action-plan-comment.component.scss'],
  providers : [ ActionPlanService ]
})
export class ActionPlanCommentComponent implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  apCommentForm: FormGroup;
  action: Number = 3;
  closingAp: Boolean = false;
  title: string;

  private createOptions(title : string, msg : string, duration: string){
      var toastOptions: ToastOptions = {
          title: title,
          msg: msg,
          showClose: true,
          timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
          theme: 'material'
          
      };
  
      return toastOptions;
  }    

  constructor(        
      @Inject(MAT_DIALOG_DATA) public data:Record<string, any>  = {},
      private formBuilder: FormBuilder,
      private dialogRef: MatDialogRef<ActionPlanCommentComponent>,      
      private toastaService: ToastaService,
      private toastaConfig: ToastaConfig,
      private actionPlanSrv: ActionPlanService
  ){
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
  }

  ngOnInit() {
    this.toastaService.error(this.createOptions('Títutlo', 'Mensaje', '3000'));
    this.action = this.data.action || 3;    
    this.title  = (this.action != 3) ? `Cerrar Plan de Acción ${this.data.apId}` : `Plan de Acción ${this.data.apId}`;
    this.apCommentForm = this.formBuilder.group({
      comentario : this.formBuilder.control({
        value    : this.data.comentario_cierre || '',
        disabled : (this.action == 3)
      }, [Validators.required])
    });
  }

  save() {
    this.closingAp = true;
    this.subscription.add(this.actionPlanSrv.closeActionPlan({
      id                : this.data.apId,
      declarationId     : this.data.declarationId,
      comentario_cierre : this.apCommentForm.controls.comentario.value
    }).subscribe(
        response => {
            this.closingAp = false;
            this.dialogRef.close({ comentario : this.apCommentForm.controls.comentario.value });
        }, error => {
          let _message = (!!error.error) ? error.error.error.message : error.message;
          this.closingAp = false;
          this.toastaService.error(this.createOptions(undefined, _message, '3000'));
          console.log(error);
        }
    ));
  }

  close() { this.dialogRef.close() }

  ngOnDestroy() {
      if (this.subscription) this.subscription.unsubscribe();
  }

}
