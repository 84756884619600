import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AlertComponent } from './alert/alert.component';
import { StatementsComponent } from './statements/statements.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PanelInformationComponent } from './panel-information/panel-information.component';
import { FieldValidationComponent } from './field-validation/field-validation.component';
import { LoadingComponent } from './loading/loading.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule} from '@angular/material/icon';
import { ToastaModule } from 'ngx-toasta';
import { NgxPaginationModule } from 'ngx-pagination';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { ActionPlanListComponent } from './action-plan-list/action-plan-list.component';
import { ActionPlanCommentComponent } from './action-plan-comment/action-plan-comment.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { ListDocumentsComponent } from './list-documents/list-documents.component';
import { BrandsComponent } from './brands/brands.component';
import { AppApexChartComponent } from './apex-chart/apex-chart.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import {MatRadioModule} from '@angular/material';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NumberOnlyDirective} from './directives/numbers-only.directive';
import { DropZoneDirective } from './directives/drop-zone.directive';


@NgModule({
  declarations: [AlertComponent, StatementsComponent, PaginationComponent, PanelInformationComponent, 
    FieldValidationComponent, LoadingComponent, ActionPlanComponent, ActionPlanListComponent, 
    AddDocumentComponent, ListDocumentsComponent, BrandsComponent, AppApexChartComponent, NumberOnlyDirective, DropZoneDirective,
    ActionPlanCommentComponent],
  imports: [
    CommonModule,
    NgbModule,
    MatTooltipModule,
    MatIconModule,
    ToastaModule.forRoot(),
    NgxPaginationModule,
    FormsModule, 
    ReactiveFormsModule,
    SwiperModule,
    MatDialogModule,
    MatSortModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgApexchartsModule
  ],
  exports : [
    MatTooltipModule,
    MatIconModule,
    ToastaModule,
    MatDialogModule,
    MatSortModule,
    MatRadioModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    AlertComponent,
    StatementsComponent,
    PaginationComponent,
    PanelInformationComponent,
    FieldValidationComponent,
    LoadingComponent,
    NgxPaginationModule,
    ActionPlanComponent,
    ActionPlanListComponent,
    ActionPlanCommentComponent,
    AddDocumentComponent,
    ListDocumentsComponent,
    BrandsComponent,
    AppApexChartComponent,
    NumberOnlyDirective,
    DropZoneDirective
  ],
  entryComponents:[
    ActionPlanCommentComponent
  ]
})
export class SharedModule { }
