import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-login-sso',
    templateUrl: './login-sso.component.html',
    styleUrls: ['./login-sso.component.scss']
})
export class LoginSSOComponent implements OnInit {

    private token: string;

    showError: boolean = false;
    origin:string = location.origin;

    constructor(){
        let urlParams = new URLSearchParams(location.search);
        if (urlParams.has('token')) this.token = urlParams.get('token');
    }

    ngOnInit(){
        if (!!this.token){
            window.localStorage.setItem('token', this.token);
            setTimeout(() => { location.href = this.origin }, 2000);
        } else this.showError = true;
    }

}