<button mat-raised-button tabIndex="-1" #customTooltip="matTooltip" class="bg-validate btn-validate h-100 border-radius-0 cursor-default" 
  *ngIf="control.pristine || control.value == ''"  [matTooltipPosition]="position.value" matTooltip="{{tooltip}}" (click)="customTooltip.show()">
  <mat-icon class="mat-icon-sm text-white">help</mat-icon>
</button>

<button mat-raised-button tabIndex="-1" class="bg-success btn-validate h-100 border-radius-0 cursor-default" 
  *ngIf="control.dirty && control.valid && control.value != ''">
  <mat-icon class="mat-icon-sm text-white">check_circle</mat-icon>
</button>


<button mat-raised-button tabIndex="-1" #customTooltip="matTooltip" class="bg-warn btn-validate h-100 border-radius-0 cursor-default" 
  *ngIf="control.invalid && control.dirty && control.value != ''" [matTooltipPosition]="position.value" matTooltip="{{tooltip}}" (click)="customTooltip.show()">
  <mat-icon class="mat-icon-sm text-white" >cancel</mat-icon>
</button>
