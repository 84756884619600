
<div class="row justify-content-center no-gutters vertical-align">
    <div class="col-lg-4 col-md-6 bg-white">
    <div class="login-fancy pb-20 clearfix">
        <h3 class="mb-10 text-center">Sesión Expirada</h3>
        <div class="text-center">Su sesión ha finalizado, por favor vuelva a a conectarse</div>
        <div class="section-field mb-10">
        <img class="img-fluid" src="assets/images/error_fixed.png" alt="">
        </div>
        <div class="section-field">
        <div class="col-xs-12 text-center">
            <a (click)="goToPage('/login')" class="font-medium deco-under cursor-pointer">Login</a>
        </div>
        </div>
    </div>
    </div>
</div>
