import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountService } from '@services/account.service';
import { I18nService } from '@services/i18n.service';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
  providers : [ AccountService ]
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private accountSrv : AccountService, 
    private toastaService: ToastaService, private toastaConfig: ToastaConfig, private i18n : I18nService) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
  }
  private subscription: Subscription = new Subscription();
  private token : string;
  ngOnInit() {
    this.subscription.add(this.activatedRoute.params.subscribe(
      params => {
        this.token = params['hash'];
        if (this.token && this.token != ''){
          setTimeout (() => {
            this.checkHash();
          }, 200);
        } else {
          this.router.navigate(['/login']);
        }
      }
    ));
  }

  private checkHash(){
    this.subscription.add(this.accountSrv.activate(this.token).subscribe(
      response => {
        this.success('', this.i18n.getKeyWithParameters('acc_activated', { fullname :response }), '');
        setTimeout (() => {
          this.router.navigate(['/login']);
        }, 4000);
        
      },
      response =>    {
        this.error('', this.i18n.getKey(response.error.error.message), '');
        setTimeout (() => {
          this.router.navigate(['/login']);
        }, 4000);
      },
    ));
  }

  success(title : string, msg : string, duration: string){
    this.toastaService.success(this.createOptions(title, msg, duration));
  }
  error(title : string, msg : string, duration: string){
      this.toastaService.error(this.createOptions(title, msg, duration));  
  }

  private createOptions(title : string, msg : string, duration: string){
      var toastOptions: ToastOptions = {
          title: title,
          msg: msg,
          showClose: true,
          timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
          theme: ConstantService.ToasterTheme
          
      };

      return toastOptions;
  }
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
