import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from "rxjs";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { ConstantService } from '@services/constant.service';
import { SessionService } from '@services/session.service';

import CryptoJS from 'crypto-js/core';
import SHA1 from 'crypto-js/sha1';
import SHA512 from 'crypto-js/sha512';

@Injectable()
export class AccountService {

    head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
    session = { token:"" };

    constructor(public http: HttpClient, private sessionService : SessionService) { 
      console.info(`%cVersión: ${environment.appVersion}`, 'background:linear-gradient(#000, #555); color:#fff; padding: 5px 10px;');
    }

    encryptPassword(password: string) : string{
      let _pass = SHA1(password).toString(CryptoJS.enc.Hex);
      _pass = SHA512(_pass).toString(CryptoJS.enc.Hex);
      return _pass;
    }

    authenticate(rut: string, password: string, documentId : Number) : Observable<any> {
      let body = { rut: rut, password : this.encryptPassword(password) , documentId : documentId};
      return this.http.post<any>(environment.serverUrl + EndpointService.Authentication_Authenticate, body, { headers: this.head })
        .pipe(map( response => { 
          localStorage.setItem('token', response.token);
          return response 
        })
      );
    }

    register(user: any) : Observable<any> {

      let body = {
        usuario : { 
          rut              : user.rut, 
          documentIdentity : user.documentIdentity, 
          email            : user.email, 
          firstName        : user.name, 
          lastName         : user.lastName, 
          secondLastName   : user.lastSecondName, 
          supervisorEmail  : user.supervisorEmail,
          internalCompany  : user.company, 
          password         : this.encryptPassword(user.password)
        }
      };

      return this.http.post<any>(environment.serverUrl + EndpointService.Account_Register, body, { headers: this.head })
        .pipe(map( response => { return response })
      );

  }
  
   isAuthenticated() : Observable<Boolean> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Authentication_IsLoggedIn).pipe(
        map(response => { 
          return response; 
        }), catchError( error => {
          this.removeSessionStorate();
          return throwError(false);
        })    
      );
    }
  
    logout() : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Authentication_Authenticate)
        .pipe(map( response => { 
          this.removeSessionStorate();
          return response; 
        })
      );
    }

    private removeSessionStorate(){
      localStorage.removeItem("token")
      localStorage.removeItem("representation");
    }

    forgotPassword(rut : String) : Observable<any> {
        let body = {rut: rut };
        return this.http.post<any>(environment.serverUrl + EndpointService.RecoverPassword_Request, body, { headers: this.head })
        .pipe(map( response => { return response }));
    }

    setPassword(rut: string, password: string) : Observable<any> {
      let body = { rut: rut, password : this.encryptPassword(password) };
      return this.http.post<any>(environment.serverUrl + EndpointService.Recover_SetPassword, body, { headers: this.head })
        .pipe(map( response => { 
          return response 
        })
      );
    }

    validateHashForgotPassword(hash : string ) : Observable<any> {
      return this.http.get(environment.serverUrl + EndpointService.Recover_CheckHash + hash, { responseType : "text" })
        .pipe(map( response => { return response })
      );
    }

    updatePassword(rut : string, password: string, token: string) : Observable<any> {
      let body = { rut: rut, password : this.encryptPassword(password), hash: token };
      return this.http.put<any>(environment.serverUrl + EndpointService.Recover_UpdatePassword, body, { headers: this.head })
        .pipe(map( response => { 
          return response 
        })
      );
    }

    getRepresentatives(rut : String) : Observable<any> {
        return this.http.get<any>(environment.serverUrl + EndpointService.Representatives_List + rut).pipe(
            map(response => { 
              return response; 
            })    
        );
    }

    confirmRepresentation(rut : String, representTo : String) : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Representatives_Root + "/confirm/" + rut + "/" + representTo).pipe(
          map(response => { 
            return response; 
          })    
      );
  }

    activate(token: string): Observable<any> {
        return this.http.get(environment.serverUrl + EndpointService.Account_Activate + token, { responseType : "text" }).pipe(
          map(response => {
            return response;
          })
        );
    }
    
    getProfile(): Observable<any> {
        return this.http.get<any>(environment.serverUrl + EndpointService.Account_Profile).pipe(
          map(response => {
            localStorage.setItem('profile', response);
            return response;
          })
        );
    }

    getSupervisors(search : string) : Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.Account_Supervisors + search)
      .pipe(map(response => {return response; }));
    }
}