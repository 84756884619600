import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-field-validation',
  templateUrl: './field-validation.component.html',
  styleUrls: ['./field-validation.component.scss']
})
export class FieldValidationComponent implements OnInit {
  @Input() control : FormControl
  @Input() tooltip : string
  positionOptions: TooltipPosition[] = ['before'];
  position = new FormControl(this.positionOptions[0]);
  constructor() { }

  ngOnInit() {
  }

}
