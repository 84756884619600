import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@services/i18n.service';
import { format } from 'rut.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { selectAnOptionValidator } from '@utils/app-validators';
import { Subscription, forkJoin } from 'rxjs';
import { DeclarationService } from '@services/declaration.service';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { SessionService } from '@services/session.service';
import { ActionPlanService } from '@services/action-plan.service';
import { FiltersService } from '@services/filters.service';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
  providers: [ DeclarationService, ActionPlanService ]
})
export class StatementsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  statementsFiltered:boolean = false;
  isRep:number = 0;
  statements: any = null;
  rut:string;
  profile: any;
  declarationTypes = [];
  statusTypes = [];
  filterForm: FormGroup;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }

  constructor(
    public i18n : I18nService,
    public formBuilder: FormBuilder,
    private router : Router,
    private declarationSrv : DeclarationService,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private sessionService : SessionService,
    private filterService: FiltersService
  ) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
      this.declarationTypes.push({ id: 1, name : this.i18n.getKey('declaration.type1') });
      this.declarationTypes.push({ id: 2, name : this.i18n.getKey('declaration.type2') });
      this.declarationTypes.push({ id: 3, name : this.i18n.getKey('declaration.type3') });
      this.statusTypes.push({ id: 1, name : this.i18n.getKey('declaration.status1') });
      this.statusTypes.push({ id: 2, name : this.i18n.getKey('declaration.status2') });
      this.statusTypes.push({ id: 3, name : this.i18n.getKey('declaration.status3') });
      this.filterForm = this.formBuilder.group({
        id: this.formBuilder.control({value:'', disabled: false},[Validators.required]),
        representing: this.formBuilder.control({value: null, disabled: false},[Validators.required]),
        represented: this.formBuilder.control({value: null, disabled: false},[Validators.required]),
        type: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator]),
        status: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator])
      });
  }

  ngOnInit() {
    this.profile = this.sessionService.getProfile();

    this.subscription.add(this.sessionService.representantion$.subscribe(
      rut => {
        this.checkUserDeclaration();
      }
    ));

    setTimeout (() => {
     this.checkUserDeclaration();
    }, 200);
  }

  private checkUserDeclaration(){
    this.cleanFilters();
    if (localStorage.getItem("representation") ){
      if (this.profile.rut != localStorage.getItem("representation").toString()){
        this.rut = localStorage.getItem("representation").toString();
        this.filterForm.controls.representing.setValue(true);
      } else {
        this.rut = this.profile.rut;
        this.filterForm.controls.representing.setValue(false);
      }
      this.getCountDeclarations();
    } else {
      this.rut = this.profile.rut;
      this.filterForm.controls.representing.setValue(false);
      this.getIsRepresented();
    }
  }

  private cleanFilters(){
    this.statementsFiltered = false;
    this.filterForm.controls.id.setValue("");
    this.filterForm.controls.type.setValue("0");
    this.filterForm.controls.status.setValue("0");
    this.filterForm.controls.representing.setValue(null);
    this.filterForm.controls.represented.setValue(null);

    const filters = this.filterService.get("statements");

    if(filters){
      this.statementsFiltered = true;
      this.filterForm.patchValue(filters);
    }
  }

  private getIsRepresented(){
    this.subscription.add(this.declarationSrv.isRepresented(this.rut).subscribe(
      response => {
        this.isRep = response.count;
        if (this.isRep > 0){
          this.filterForm.controls.represented.setValue(true);
        } else {
          this.filterForm.controls.represented.setValue(false);
        }
        this.getCountDeclarations();
      },
      response =>    {
        this.getCountDeclarations();
      },
    ));
  }

  private getCountDeclarations(){
    this.subscription.add(this.declarationSrv.getUserCountDeclarations(this.rut, this.getFilter()).subscribe(
      response => {
        this.config.totalItems = response;
        this.getMyStatements()
      },
      response =>    {
        this.getMyStatements()
      },
    ));
  }

  private getFilter(){
    return {
      id: this.filterForm.controls.id.value,
      type: this.filterForm.controls.type.value != '0' ? this.getDeclarationTypeId(this.filterForm.controls.type.value) : '0',
      status: this.filterForm.controls.status.value != '0' ? this.getDeclarationStatusId(this.filterForm.controls.status.value) : '0',
      page: this.config.currentPage,
      representing: this.filterForm.controls.representing.value,
      represented: this.filterForm.controls.represented.value
    }
  }

  goToDetail(code : String){
    this.router.navigate(["/statement/detail/", code]);
  }

  goToDuplicate(state){
    this.router.navigate(["/statement/add/", state.typeId], { queryParams: { ref: state.id } });
  }

  getTypeDeclaration(id:number){
    if (id == 1){
      return this.i18n.getKey("declaration.type1");
    }
    if (id == 2){
      return this.i18n.getKey("declaration.type2");
    }
    if (id == 3){
      return this.i18n.getKey("declaration.type3");
    }
  }

  private getMyStatements(){
    this.subscription.add(this.declarationSrv.getUserDeclarations(this.rut, this.getFilter()).subscribe(
      response => {
        let richDeclarations = [];
        for (let i = 0; i < response.length; i++){
          richDeclarations.push({});
          richDeclarations[i]['id'] = response[i].id;
          richDeclarations[i]['user'] = response[i].user.length == 9 ? format(response[i].user) : response[i].user;
          richDeclarations[i]['type'] = this.getTypeDeclaration(response[i].typeDeclarationId);
          richDeclarations[i]['typeId'] = response[i].typeDeclarationId;
          richDeclarations[i]['description'] = response[i].description;
          richDeclarations[i]['nextRevision'] = response[i].nextRevision ? this.formatDate(response[i].nextRevision) : "";
          richDeclarations[i]['status'] = this.getStatus(response[i].status);
        }
        this.statements = richDeclarations;
      }, error => {
        this.statements = [];
      }
    ));
  }

  private getDeclarationTypeId(name : string){
    let selected = this.declarationTypes.filter(row => {
      return row.name == name;
    });
    return selected[0].id;
  }

  private getDeclarationStatusId(name : string){
    let selected = this.statusTypes.filter(row => {
      return row.name == name;
    });
    return selected[0].id;
  }

  private getStatus(id:number){
    if (id == 1){
      return this.i18n.getKey("declaration.status1");
    }
    if (id == 2){
      return this.i18n.getKey("declaration.status2");
    }
    if (id == 3){
      return this.i18n.getKey("declaration.status3");
    }
  }

  private formatDate(date : string){
    date = date.substring(0, 10);
    let arDate = date.split("-");
    return arDate[2] + "-" +  arDate[1] + "-" +  arDate[0];
  }

  private formatRut(declaration : any){
    if (declaration.representTo == null || declaration.representTo == ''){
      return format(declaration.rut);
    } else {
      return format(declaration.representTo);
    }
  }

  public onPageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.getMyStatements();
  }

  changeFilter(){
    this.statementsFiltered = true;
    this.getCountDeclarations();
  }

  error(title : string, msg : string, duration: string){
    this.toastaService.error(this.createOptions(title, msg, duration));
  }

  private createOptions(title : string, msg : string, duration: string){
    var toastOptions: ToastOptions = {
        title: title,
        msg: msg,
        showClose: true,
        timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
        theme: ConstantService.ToasterTheme

    };

    return toastOptions;
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.filterService.set("statements", this.filterForm.value);
  }
}
