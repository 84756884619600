/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./panel-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading/loading.component.ngfactory";
import * as i3 from "../loading/loading.component";
import * as i4 from "../brands/brands.component.ngfactory";
import * as i5 from "../brands/brands.component";
import * as i6 from "@angular/common";
import * as i7 from "./panel-information.component";
import * as i8 from "../../services/util.service";
var styles_PanelInformationComponent = [i0.styles];
var RenderType_PanelInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PanelInformationComponent, data: {} });
export { RenderType_PanelInformationComponent as RenderType_PanelInformationComponent };
function View_PanelInformationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i2.View_LoadingComponent_0, i2.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PanelInformationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "not-loading"]], null, null, null, null, null))], null, null); }
export function View_PanelInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "bg-fancy text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-brands", [], null, null, null, i4.View_BrandsComponent_0, i4.RenderType_BrandsComponent)), i1.ɵdid(3, 4308992, null, 0, i5.BrandsComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-12 p-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelInformationComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PanelInformationComponent_2)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_0 = _co.loading; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_PanelInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-panel-information", [], null, null, null, View_PanelInformationComponent_0, RenderType_PanelInformationComponent)), i1.ɵdid(1, 114688, null, 0, i7.PanelInformationComponent, [i8.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PanelInformationComponentNgFactory = i1.ɵccf("app-panel-information", i7.PanelInformationComponent, View_PanelInformationComponent_Host_0, {}, {}, []);
export { PanelInformationComponentNgFactory as PanelInformationComponentNgFactory };
