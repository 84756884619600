import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SessionService } from './session.service';
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    authorization:Boolean;

    constructor(private router: Router, public http: HttpClient, private sessionService: SessionService) { }
    /**
     * @author Carlos Castañeda Cordero
     * @description AuthGuard se encarga de consultar al servicio de Authenticacion para
     * conocer si el usuario que realiza la peticion se encuentra authenticado.
     * @param route 
     * @param state 
     * @returns CanActivate
     */
    canActivate() {
        return this.http.get<any>(environment.serverUrl + EndpointService.Authentication_IsLoggedIn).pipe(
            map(response => {
                this.sessionService.setProfile(response.profile);
                return response.valid;
            }), 
            catchError(err => {
                localStorage.removeItem("token")
                localStorage.removeItem("representation");
                if (err.error && err.error.error && err.error.error.message.indexOf("jwt expired") > -1){
                    this.router.navigate(['expired']);
                } else {
                    this.router.navigate(['login']);
                }
                return err;
            })    
        );
    }
}