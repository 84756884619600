import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { EndpointService } from "@services/endpoint.service";
import { ConstantService } from '@services/constant.service';
@Injectable()
export class MiscService {
    head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
    constructor(public http: HttpClient) { }

    getInternalCompanies(): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.InternalCompany_List).pipe(
          map(response => { return response; })
      );
    }

    getClassificationPerson(): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.ClassificationPerson).pipe(
        map(response => { return response; })
     );
    }

    getEntityMeeting(): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.EntityMeeting).pipe(
        map(response => { return response; })
     );
    }

    getEntityRelationship(typeDeclarationId : number): Observable<any> {
      var filter = '{ "where" : { "typeDeclarationId": "' + typeDeclarationId + '" }}';
      return this.http.get<any>(environment.serverUrl + EndpointService.EntityRelationship + "?filter=" + encodeURIComponent(filter)).pipe(
        map(response => { return response; })
      );
    }

    getTypeRelationship(): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.TypeRelationship).pipe(
        map(response => { return response; })
      );
    }

    getInteractionType(): Observable<any> {
      return this.http.get<any>(environment.serverUrl + EndpointService.InteractionType).pipe(
        map(response => { return response; })
      );
    }

    getTypeHospitalityGift() : Observable<any>{
      return this.http.get<any>(environment.serverUrl + EndpointService.TypeHospitalityGift).pipe(
        map(response => { return response; })
      );
    }

    checkMultipleCompaniesByName(companies : any) : any {
      var requests = [];
      for (let i = 0; i < companies.length; i++){
        requests.push(this.http.get<any>(environment.serverUrl + EndpointService.Companies + "/name/" + companies[i].company));
      }
      return requests;
    }

    checkMultipleCompaniesByPersonId(persons : any) : any {
      var requests = [];
      for (let i = 0; i < persons.length; i++){
        requests.push(this.http.get<any>(environment.serverUrl + EndpointService.Companies + "/" + persons[i].companyId));
      }
      return requests;
    }

    createMultipleCompanies(companies : any) : any {
      var requests = [];
      for (let i = 0; i < companies.length; i++){
        requests.push(this.http.post<any>(environment.serverUrl + EndpointService.Companies, companies[i], { headers: this.head }));
      }
      return requests;
    }

    createMultiplePersons(persons : any) : any {
      var requests = [];
      for (let i = 0; i < persons.length; i++){
        let person = {
          charge : persons[i].charge,
          fullname : persons[i].fullname,
          companyId : persons[i].companyId,
          classificationPersonId : persons[i].classificationPersonId,
          observation : persons[i].observation || "",
          entityRelationshipId : persons[i].relation,
          typeRelationshipId : persons[i].typeRelation
        };
        requests.push(this.http.post<any>(environment.serverUrl + EndpointService.People, person, { headers: this.head }));
      }
      return requests;
    }

    checkMultiplePeople(persons : any){
      var requests = [];
      for (let i = 0; i < persons.length; i++){
        requests.push(this.http.get<any>(environment.serverUrl + EndpointService.People + "/" + persons[i].personId));
      }
      return requests;
    }

  createMultipleMeetingPersons(declarationId: number, persons: any): any {
    var requests = [];
    for (let i = 0; i < persons.length; i++) {
      let meetingPersons = {
        declarationMeetingId: declarationId,
        personId: persons[i].id
      }

      requests.push(this.http.post<any>(environment.serverUrl + EndpointService.MeetingPersons, meetingPersons, { headers: this.head }));
    }
    return requests;
  }

  createMultipleConflictPersons(declarationId: number, persons: any): any {
    var requests = [];

    for (let i = 0; i < persons.length; i++) {
      let conflictPersons = {
        declarationConflictId: declarationId,
        personId: persons[i].id,
      }
      requests.push(this.http.post<any>(environment.serverUrl + EndpointService.ConflictPersons, conflictPersons, { headers: this.head }));
    }
    return requests;
  }

    saveMeetingPerson(meetingPerson : any){
      return this.http.post<any>(environment.serverUrl + EndpointService.MeetingPersons, meetingPerson, { headers: this.head }).pipe(
        map(response => { return response; })
      );
    }

    savePerson(person : any) : Observable<any>{
      return this.http.post<any>(environment.serverUrl + EndpointService.People, person, { headers: this.head }).pipe(
        map(response => { return response; })
      );
    }

    getPersonId(id : any) : Observable<any>{
      return this.http.get<any>(environment.serverUrl + EndpointService.People + "/" + id).pipe(
        map(response => { return response; })
      );
    }

    getCompanyById(id: string){
      return this.http.get<any>(environment.serverUrl + EndpointService.Companies + "/" + id).pipe(
        map(response => { return response; })
      );
    }

    getCompanyByName(name: string){
      return this.http.get<any>(environment.serverUrl + EndpointService.Companies + "/name/" + name).pipe(
        map(response => { return response; })
      );
    }

    saveCompany(company : any) : Observable<any>{
      return this.http.post<any>(environment.serverUrl + EndpointService.Companies, company, { headers: this.head }).pipe(
        map(response => { return response; })
      );
    }
}
