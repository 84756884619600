import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {Sort} from '@angular/material/sort';
import { I18nService } from '@services/i18n.service';
import { format, clean, validate } from 'rut.js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { selectAnOptionValidator } from '@utils/app-validators';
import { Subscription, forkJoin, of } from 'rxjs';
import { DeclarationService } from '@services/declaration.service';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { SessionService } from '@services/session.service';
import { ActionPlanService } from '@services/action-plan.service';
import { MiscService } from '@services/misc.service';
import { FiltersService } from '@services/filters.service';

@Component({
  selector: 'app-search-statements',
  templateUrl: './search-statements.component.html',
  styleUrls: ['./search-statements.component.scss'],
  providers: [ DeclarationService, ActionPlanService, MiscService]
})
export class SearchStatementsComponent implements OnInit {

  private subscription: Subscription = new Subscription();
  statementsFiltered:boolean = false;
  statements: any = null;
  companies: any;
  profile: any;
  declarationTypes = [];
  statusTypes = [];
  filterForm: FormGroup;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0
  }
  recentlySearch:boolean=false;

  constructor(
    public i18n: I18nService,
    public formBuilder: FormBuilder,
    private router: Router,
    private declarationSrv: DeclarationService,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private sessionService : SessionService,
    private miscService: MiscService,
    private filterService: FiltersService
  ) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
      this.declarationTypes.push({ id: 1, name : this.i18n.getKey('declaration.type1') });
      this.declarationTypes.push({ id: 2, name : this.i18n.getKey('declaration.type2') });
      this.declarationTypes.push({ id: 3, name : this.i18n.getKey('declaration.type3') });
      this.statusTypes.push({ id: 1, name : this.i18n.getKey('declaration.status1') });
      this.statusTypes.push({ id: 2, name : this.i18n.getKey('declaration.status2') });
      this.statusTypes.push({ id: 3, name : this.i18n.getKey('declaration.status3') });
      this.filterForm = this.formBuilder.group({
        id: this.formBuilder.control({value:'', disabled: false},[Validators.required]),
        rut: this.formBuilder.control({value:'', disabled: false},[Validators.required]),
        lastName: this.formBuilder.control({value:'', disabled: false},[Validators.required]),
        sociedad: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator]),
        type: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator]),
        status: this.formBuilder.control({value:'0', disabled: false},[Validators.required, selectAnOptionValidator]),
        orden: undefined
      });
  }

  ngOnInit() {
    this.profile = this.sessionService.getProfile();

    this.subscription.add(this.sessionService.representantion$.subscribe(
      rut => {
        this.checDeclarations();
      }
    ));

    this.getInternalCompanies();

    setTimeout (() => {
     this.checDeclarations();
    }, 200);
  }

  private checDeclarations(){
    this.cleanFilters();
    this.getSupervisorDeclarations();
  }

  private getInternalCompanies(){
    this.subscription.add(this.miscService.getInternalCompanies().subscribe(
      response => {
        this.companies = response;
      }, error => {
        this.companies = [];
      }
    ));
  }

  private cleanFilters(){
    this.statementsFiltered = false;
    this.filterForm.controls.id.setValue("");
    this.filterForm.controls.rut.setValue("");
    this.filterForm.controls.type.setValue("0");
    this.filterForm.controls.status.setValue("0");
    this.filterForm.controls.lastName.setValue("");
    this.filterForm.controls.sociedad.setValue("0");

    const filters = this.filterService.get("search");

    if(filters){
      this.statementsFiltered = true;
      this.filterForm.patchValue(filters);
    }
  }

  private getFilter(){
    return {
      id: this.filterForm.controls.id.value,
      rut: this.filterForm.controls.rut.value != '' ? clean(this.filterForm.controls.rut.value) : '',
      lastName: this.filterForm.controls.lastName.value,
      type: this.filterForm.controls.type.value != '0' ? this.getDeclarationTypeId(this.filterForm.controls.type.value) : '0',
      status: this.filterForm.controls.status.value != '0' ? this.getDeclarationStatusId(this.filterForm.controls.status.value) : '0',
      sociedad: this.filterForm.controls.sociedad.value != '0' ? this.filterForm.controls.sociedad.value : undefined,
      roleId: this.profile.roleId,
      internalCompany : this.profile.internalCompany,
      orden: this.filterForm.controls.orden.value
    }
  }

  getSupervisorDeclarations(){
    this.subscription.add(this.declarationSrv.getAllDeclarations(this.getFilter()).subscribe(
      response => {
        let richDeclarations = [];
        for (let i = 0; i < response.length; i++){
          richDeclarations.push({});
          richDeclarations[i]['id'] = response[i].id;
          richDeclarations[i]['creationDate'] = response[i].creationDate ? this.formatDate(response[i].creationDate) : "";
          richDeclarations[i]['user'] = response[i].user.length == 9 ? format(response[i].user) : response[i].user;
          richDeclarations[i]['firstName'] = response[i].firstName;
          richDeclarations[i]['lastName'] = response[i].lastName;
          richDeclarations[i]['type'] = this.getTypeDeclaration(response[i].typeDeclarationId);
          richDeclarations[i]['typeId'] = response[i].typeDeclarationId;
          richDeclarations[i]['description'] = response[i].description;
          richDeclarations[i]['nextRevision'] = response[i].nextRevision ? this.formatDate(response[i].nextRevision) : "";
          richDeclarations[i]['status'] = this.getStatus(response[i].status);
          richDeclarations[i]['sociedad'] = response[i].sociedad;
          richDeclarations[i]['email'] = response[i].email;
        }
        this.statements = richDeclarations;
      },
      response =>    {
        this.statements = [];
      },
    ));
  }


  goToDetail(code : String){
    this.router.navigate(["/statement/detail/", code]);
  }

  goToDuplicate(state){
    this.router.navigate(["/statement/add/", state.typeId], { queryParams: { ref: state.id } });
  }

  getTypeDeclaration(id:number){
    if (id == 1){
      return this.i18n.getKey("declaration.type1");
    }
    if (id == 2){
      return this.i18n.getKey("declaration.type2");
    }
    if (id == 3){
      return this.i18n.getKey("declaration.type3");
    }
  }

  private getDeclarationTypeId(name : string){
    let selected = this.declarationTypes.filter(row => {
      return row.name == name;
    });
    return selected[0].id;
  }

  private getDeclarationStatusId(name : string){
    let selected = this.statusTypes.filter(row => {
      return row.name == name;
    });
    return selected[0].id;
  }

  private getStatus(id:number){
    if (id == 1){
      return this.i18n.getKey("declaration.status1");
    }
    if (id == 2){
      return this.i18n.getKey("declaration.status2");
    }
    if (id == 3){
      return this.i18n.getKey("declaration.status3");
    }
  }

  private formatDate(date : string){
    date = date.substring(0, 10);
    let arDate = date.split("-");
    return arDate[2] + "-" +  arDate[1] + "-" +  arDate[0];
  }

  private formatRut(rut : any){
      return format(rut);
  }

  public onPageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.getSupervisorDeclarations();
  }

  changeFilter(){
    this.statementsFiltered = true;
    this.getSupervisorDeclarations();
  }

  sortData(sort: Sort){
    if (!sort.direction) this.filterForm.controls.orden.setValue(undefined)
    else if (sort.direction == 'asc') this.filterForm.controls.orden.setValue(`${sort.active},1`);
    else this.filterForm.controls.orden.setValue(`${sort.active},-1`);
    this.getSupervisorDeclarations();
  }

  onBlurRut() {
    if (this.filterForm.controls.rut.value != '') {
      if (this.filterForm.controls.rut.value.length > 7 && validate(this.filterForm.controls.rut.value)) {
        this.filterForm.controls.rut.setErrors(null);
        //this.filterForm.controls.rut.setValue(format(this.filterForm.controls.rut.value));
        this.recentlySearch = true;
        this.statementsFiltered = true;
        this.getSupervisorDeclarations();
      } else {
        this.filterForm.controls.rut.setErrors({'incorrect': true});
      }
      this.filterForm.controls.rut.markAsDirty();
    } else if (this.recentlySearch) {
      this.getSupervisorDeclarations();
      this.recentlySearch = false;
    }
  }

  error(title : string, msg : string, duration: string){
    this.toastaService.error(this.createOptions(title, msg, duration));
  }

  private createOptions(title : string, msg : string, duration: string){
    var toastOptions: ToastOptions = {
        title: title,
        msg: msg,
        showClose: true,
        timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
        theme: ConstantService.ToasterTheme

    };

    return toastOptions;
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    this.filterService.set("search", this.filterForm.value);
  }

}
