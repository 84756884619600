import * as i0 from "@angular/core";
export class DniArgentinaService {
    constructor() { }
    //Handler para el evento cuando cambia el input
    //Elimina cualquier caracter espacio o signos habituales y comprueba validez
    validaDni(input) {
        var dni = input.replace(/[-.,[\]()\s]+/g, "");
        //     resultado = document.getElementById("resultado"),
        //     existente = document.getElementById("existente"),
        //     valido;
        // existente.innerHTML = "";
        //Es entero? 
        if (this.dniValido(dni) && (dni = Number(dni)) && dni % 1 === 0) { // ⬅️ Acá se comprueba
            return true;
        }
        else {
            return false;
        }
        // resultado.innerText = "RUC: " + ruc + "\nFormato: " + valido;
    }
    // Devuelve un booleano si es un DNI válido
    // (deben ser 8 dígitos sin otro caracter en el medio)
    dniValido(dni) {
        var regexDni = /^\d{8}(?:[-\s]\d{4})?$/;
        if (regexDni.test(dni) == true) {
            return true;
        }
        else {
            return false;
        }
    }
}
DniArgentinaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DniArgentinaService_Factory() { return new DniArgentinaService(); }, token: DniArgentinaService, providedIn: "root" });
