/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/statements/statements.component.ngfactory";
import * as i3 from "../../../services/action-plan.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../services/declaration.service";
import * as i6 from "../../../services/session.service";
import * as i7 from "../../../shared/statements/statements.component";
import * as i8 from "../../../services/i18n.service";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/router";
import * as i11 from "ngx-toasta";
import * as i12 from "../../../services/filters.service";
import * as i13 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "flow-wrap col-12 mb-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "app-statements", [["class", "col-12 p-0"]], null, null, null, i2.View_StatementsComponent_0, i2.RenderType_StatementsComponent)), i1.ɵprd(4608, null, i3.ActionPlanService, i3.ActionPlanService, [i4.HttpClient]), i1.ɵprd(512, null, i5.DeclarationService, i5.DeclarationService, [i4.HttpClient, i6.SessionService]), i1.ɵdid(6, 245760, null, 0, i7.StatementsComponent, [i8.I18nService, i9.FormBuilder, i10.Router, i5.DeclarationService, i11.ToastaService, i11.ToastaConfig, i6.SessionService, i12.FiltersService], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i13.HomeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i13.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
