import { Component, OnInit } from '@angular/core';
import { AccountService } from '@services/account.service';
import { UserManagerService } from '@services/user-manager.service';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '@services/session.service';
import { Router } from '@angular/router';
import { forkJoin } from "rxjs";
import { format } from 'rut.js';
import { ToastaService, ToastaConfig, ToastOptions } from 'ngx-toasta';
import { ConstantService } from '@services/constant.service';
import { I18nService } from '@services/i18n.service';

@Component({
  selector: 'app-representation',
  templateUrl: './representation.component.html',
  styleUrls: ['./representation.component.scss'],
  providers: [ AccountService, UserManagerService ]
})
export class RepresentationComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  isLoggedIn: any;
  relations: any;
  representatives : any = null;
  profile: any;
  selected:string = '';
  showRep:Boolean = false;
  constructor(private accountSrv : AccountService, private sessionService : SessionService, private router : Router, 
    private toastaService: ToastaService, private toastaConfig: ToastaConfig, private i18n : I18nService, private usrMgrService : UserManagerService) {
      this.toastaConfig.theme = 'material';
      this.toastaConfig.position = 'top-center';
  }

  ngOnInit() {
    this.profile = this.sessionService.getProfile();
    this.selected = this.profile.rut;
    setTimeout (() => {
      this.getRepresentatives();
    }, 200);
  }

  private getRepresentatives(){
    this.subscription.add(this.accountSrv.getRepresentatives(this.profile.rut).subscribe(
      response => {
        if (localStorage.getItem('representation') && localStorage.getItem('representation').toString() != '' && response.length > 0) {
          this.router.navigate(['/']);
        } else {
          this.relations = response;
          this.getUsers(response);
        }
          
      },
      response =>    { 
        this.error('', response.error.error.message , '');
      },
    ));
  }

  private getUsers(representatives : string){
    forkJoin(this.usrMgrService.getUsersByRUt(representatives)).subscribe(results => {
      this.representatives = results;
    });
  }

  getLeadership(rut : string){
    let selected = this.relations.filter( row => {
      return row.rut = rut;
    });
    return selected[0].leadership;
  }

  error(title : string, msg : string, duration: string){
    this.toastaService.error(this.createOptions(title, msg, duration));  
  }

  formatRut(rut : string){
    return format(rut);
  }

  confirmUser(rut : string){
    this.selected = rut;
  }

  goToPage(){
    localStorage.setItem("representation", this.selected);
    if (this.profile.rut == localStorage.getItem("representation")){
      this.router.navigate(['/']);
    } else {
      this.registerAuditAction();
    }
  }

  private registerAuditAction(){
    this.subscription.add(this.accountSrv.confirmRepresentation(this.profile.rut, localStorage.getItem("representation")).subscribe(
      response => {
        this.router.navigate(['/']);
      },
      response =>    { 
        this.error('', response.error.error.message , '');
      },
    ));
  }

  private createOptions(title : string, msg : string, duration: string){
    var toastOptions: ToastOptions = {
        title: title,
        msg: msg,
        showClose: true,
        timeout: (duration.length) ? parseInt(duration) : ConstantService.ToasterDismiss,
        theme: ConstantService.ToasterTheme
        
    };

    return toastOptions;
  }
}
