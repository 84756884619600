import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class SessionService {
    constructor() {
        this.profile = null;
        this.declarationByYear = new Subject();
        this.declarationByYear$ = this.declarationByYear.asObservable();
        this.representantion = new Subject();
        this.representantion$ = this.representantion.asObservable();
    }
    getProfile() {
        return this.profile;
    }
    setProfile(profile) {
        return this.profile = profile;
    }
    setRepresentantion(rut) {
        this.representantion.next(rut);
    }
    setDeclarationYear(declarated) {
        this.declarationYear = declarated;
    }
    getDeclarationYear() {
        return this.declarationYear;
    }
    setDeclarationByYear(declarated) {
        this.declarationByYear.next(declarated);
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(); }, token: SessionService, providedIn: "root" });
