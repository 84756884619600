/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expired.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./expired.component";
import * as i3 from "@angular/router";
var styles_ExpiredComponent = [i0.styles];
var RenderType_ExpiredComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpiredComponent, data: {} });
export { RenderType_ExpiredComponent as RenderType_ExpiredComponent };
export function View_ExpiredComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row justify-content-center no-gutters vertical-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "col-lg-4 col-md-6 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "login-fancy pb-20 clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "mb-10 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sesi\u00F3n Expirada"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Su sesi\u00F3n ha finalizado, por favor vuelva a a conectarse"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "section-field mb-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid"], ["src", "assets/images/error_fixed.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "section-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-xs-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [["class", "font-medium deco-under cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPage("/login") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"]))], null, null); }
export function View_ExpiredComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expired", [], null, null, null, View_ExpiredComponent_0, RenderType_ExpiredComponent)), i1.ɵdid(1, 114688, null, 0, i2.ExpiredComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpiredComponentNgFactory = i1.ɵccf("app-expired", i2.ExpiredComponent, View_ExpiredComponent_Host_0, {}, {}, []);
export { ExpiredComponentNgFactory as ExpiredComponentNgFactory };
